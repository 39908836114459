import React from 'react';
import { Builder } from '@shared/types/builder';
import { BasicDemoCard } from '@shared/ui/basic-demo-card';
import { useLocalPostHog } from '@shared/hooks/useLocalPostHog';
import { PosthogEvents } from '@shared/constants/posthog-events';
import { commonSizes } from '@shared/constants/builderCardSizes';
import { getBasicComponentsDetails } from '@widgets/mediakit/helpers';
import { CRUDServiceList } from '@widgets/mediakit/MediaKit/Common/CRUDServiceList';

export function ServiceList({
  data,
  size,
  seeAll,
  onAddItem,
}: Builder.DemoComponentProps) {
  const posthog = useLocalPostHog();

  const detailsFunc = getBasicComponentsDetails()[data.name];

  const { title: cardTitle, icon } = detailsFunc();
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);

  const onClick = () => {
    setIsModalOpen(true);

    try {
      let properties;
      if (seeAll) {
        properties = {
          seeAll,
        };
      }

      posthog?.capture(PosthogEvents.ServiceList, properties);
      posthog?.capture(PosthogEvents.click_any_component, {
        component: data.name,
      });
    } catch (e) {
      console.error('Posthog error:', e);
    }
  };

  return (
    <>
      <BasicDemoCard
        size={size}
        icon={icon}
        type={data.name}
        title={cardTitle}
        h={commonSizes.h}
        w={commonSizes.w}
        onClick={onClick}
        platform={data.platform}
      />

      {isModalOpen && (
        <CRUDServiceList
          data={data}
          onAddItem={onAddItem}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      )}
    </>
  );
}
