'use client';

import React from 'react';
import { Flex } from 'antd';
import Image from 'next/image';
import styled from '@emotion/styled';
import basic from './icons/basic.svg';
import tiktok from './icons/tiktok.svg';
import youtube from './icons/youtube.svg';
import insta from './icons/instagram.svg';
import basicActive from './icons/basicActive.svg';
import tiktokActive from './icons/tiktokActive.svg';
import instaActive from './icons/instagramActive.svg';
import youtubeActive from './icons/youtubeActive.svg';
import useBreakpoint from '@shared/hooks/useBreakpoint';
import { ComponentsFilters } from '@shared/constants/componentsFilters';
import {
  useDispatch,
  useSelector,
  onChangeFilter,
  selectSelectedFilter,
} from '@shared/redux';

const Div = styled('div')(() => ({
  width: 64,
  height: 64,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const Card = styled('div')(() => ({
  gap: 5,
  display: 'flex',
  cursor: 'pointer',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const wrapperStyle: React.CSSProperties = {
  width: '100%',
  paddingTop: '2rem',
  alignItems: 'center',
  paddingBottom: '2rem',
  backgroundColor: '#001529',
};

const Helper = styled('span', {
  shouldForwardProp: (prop) => prop !== 'active',
})(({ active }: { active?: boolean }) => ({
  fontSize: 14,
  lineHeight: '22px',
  fontWeight: active ? 700 : 500,
  color: 'rgba(255, 255, 255, 0.88)',
}));

const Basic = () => {
  const dispatch = useDispatch();
  const selectedFilter = useSelector(selectSelectedFilter);

  const isActive = ComponentsFilters.allBasics === selectedFilter;

  const handleChangeFilter = () => {
    document
      .querySelector('#componentsDrawer .mediakit-drawer-body')
      ?.scrollTo({
        top: 0,
        behavior: 'smooth',
      });

    dispatch(onChangeFilter(ComponentsFilters.allBasics));
  };

  return (
    <Card onClick={handleChangeFilter}>
      <Div>
        <Image alt="basic" src={isActive ? basicActive : basic} />
      </Div>
      <Helper active={isActive}>Basic</Helper>
    </Card>
  );
};

const TikTok = () => {
  const dispatch = useDispatch();
  const selectedFilter = useSelector(selectSelectedFilter);

  const isActive = ComponentsFilters.allTikTok === selectedFilter;

  const handleChangeFilter = () => {
    document
      .querySelector('#componentsDrawer .mediakit-drawer-body')
      ?.scrollTo({
        top: 0,
        behavior: 'smooth',
      });

    dispatch(onChangeFilter(ComponentsFilters.allTikTok));
  };

  return (
    <Card onClick={handleChangeFilter}>
      <Div>
        <Image alt="tiktok" src={isActive ? tiktokActive : tiktok} />
      </Div>
      <Helper active={isActive}>TikTok</Helper>
    </Card>
  );
};

const YouTube = () => {
  const dispatch = useDispatch();
  const selectedFilter = useSelector(selectSelectedFilter);

  const isActive = ComponentsFilters.allYouTube === selectedFilter;

  const handleChangeFilter = () => {
    document
      .querySelector('#componentsDrawer .mediakit-drawer-body')
      ?.scrollTo({
        top: 0,
        behavior: 'smooth',
      });

    dispatch(onChangeFilter(ComponentsFilters.allYouTube));
  };

  return (
    <Card onClick={handleChangeFilter}>
      <Div>
        <Image alt="youtube" src={isActive ? youtubeActive : youtube} />
      </Div>
      <Helper active={isActive}>YouTube</Helper>
    </Card>
  );
};

const Instagram = () => {
  const dispatch = useDispatch();
  const selectedFilter = useSelector(selectSelectedFilter);

  const isActive = ComponentsFilters.allInstagram === selectedFilter;

  const handleChangeFilter = () => {
    document
      .querySelector('#componentsDrawer .mediakit-drawer-body')
      ?.scrollTo({
        top: 0,
        behavior: 'smooth',
      });

    dispatch(onChangeFilter(ComponentsFilters.allInstagram));
  };

  return (
    <Card onClick={handleChangeFilter}>
      <Div>
        <Image alt="instagram" src={isActive ? instaActive : insta} />
      </Div>
      <Helper active={isActive}>Instagram</Helper>
    </Card>
  );
};

export const Head: React.FC = () => {
  const isXS = useBreakpoint(479);

  return (
    <Flex
      gap={24}
      justify="flex-start"
      style={{ ...wrapperStyle, paddingInline: isXS ? '1.4rem' : '2.4rem' }}
    >
      <Basic />
      <Instagram />
      <TikTok />
      <YouTube />
    </Flex>
  );
};
