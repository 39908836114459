'use client';

import React from 'react';
import { MenuProps } from 'antd/es/menu';
import { prefixCls } from '@shared/theme';
import { DownOutlined } from '@shared/ui/Icons';
import { hexToRgb } from '@shared/utils/hexToRgb';
import styled, { CSSObject } from '@emotion/styled';
import { defaultEndColor } from '@shared/constants';
import { CtaLabels } from '@shared/constants/ctaLabels';
import { fontVariants } from '@shared/constants/fontVariants';
import { IProfile } from '@widgets/mediakit/MediaKit/Profile';
import { useLocalPostHog } from '@shared/hooks/useLocalPostHog';
import { PosthogEvents } from '@shared/constants/posthog-events';
import { useSelector, selectMediakitTheme } from '@shared/redux';
import { Flex, Button, Dropdown, Skeleton, Typography } from 'antd';
import { textContrastChecker } from '@shared/utils/textContrastChecker';

const menuProps: MenuProps['items'] = [
  {
    key: CtaLabels['Partner with me'],
    label: CtaLabels['Partner with me'],
  },
  {
    key: CtaLabels['Work with me'],
    label: CtaLabels['Work with me'],
  },
  {
    key: CtaLabels['Get in touch'],
    label: CtaLabels['Get in touch'],
  },
  {
    key: CtaLabels['Contact for collabs'],
    label: CtaLabels['Contact for collabs'],
  },
  {
    key: CtaLabels["Let's team up"],
    label: CtaLabels["Let's team up"],
  },
];

const StyledButton = styled(Button, {
  shouldForwardProp: (propName) => {
    return !['textColor', 'backgroundColor'].includes(propName);
  },
})<{
  textColor?: CSSObject['color'];
  backgroundColor?: CSSObject['backgroundColor'];
}>(({ textColor, backgroundColor }) => ({
  borderRadius: 99,
  position: 'relative',
  [`&.${prefixCls}-btn-primary:not(:disabled):not(.${prefixCls}-btn-disabled)`]:
    {
      backgroundColor,
      color: textColor,
      boxShadow: 'none',
      '&:hover': {
        backgroundColor: `${backgroundColor}E0`,
      },
    },
}));

const ActionContainer = styled('div')(() => ({
  gap: '1rem',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
}));

const IconContainer = styled('div')(() => ({
  top: 0,
  right: 0,
  bottom: 0,
  marginRight: 9,
  display: 'flex',
  color: '#FFFFFFA6',
  marginBlock: 'auto',
  position: 'absolute',
  alignItems: 'center',
}));

export const ActionButton: React.FC<{
  isEditing?: boolean;
  cta: IProfile['cta'];
  showSkeleton?: boolean;
  setProfileData: React.Dispatch<React.SetStateAction<IProfile>>;
}> = (props) => {
  let { isEditing, cta, showSkeleton, setProfileData } = props;
  cta = cta || '';

  const posthog = useLocalPostHog();
  const theme = useSelector(selectMediakitTheme);

  const primaryColor = theme?.primary_color || defaultEndColor;

  const { r, g, b } = hexToRgb(primaryColor);
  const buttonTextColor = textContrastChecker(r, g, b);

  const onSelect: MenuProps['onSelect'] = (e) => {
    setProfileData((prevState) => ({
      ...prevState,
      cta: e.selectedKeys[0],
    }));

    try {
      posthog?.capture(PosthogEvents.PartnerBtnClicked, {
        text: e.selectedKeys[0],
      });
    } catch (e) {
      console.error('Posthog error:', e);
    }
  };

  if (showSkeleton) {
    return <Skeleton.Button block size="large" shape="round" active />;
  }

  return isEditing ? (
    <ActionContainer>
      <Flex>
        <Typography.Text strong>Action Button</Typography.Text>
      </Flex>
      {showSkeleton ? (
        <Skeleton.Button block size="large" />
      ) : (
        <Dropdown
          placement="top"
          trigger={['click']}
          menu={{
            onSelect,
            items: menuProps,
            selectable: true,
            selectedKeys: [cta],
          }}
        >
          <StyledButton
            block
            size="large"
            type="primary"
            textColor={buttonTextColor}
            className={fontVariants.opt2}
            backgroundColor={primaryColor}
          >
            {cta}
            <IconContainer>
              <DownOutlined fill={buttonTextColor} />
            </IconContainer>
          </StyledButton>
        </Dropdown>
      )}
    </ActionContainer>
  ) : (
    <StyledButton
      block
      size="large"
      type="primary"
      textColor={buttonTextColor}
      className={fontVariants.opt2}
      backgroundColor={primaryColor}
    >
      {cta}
    </StyledButton>
  );
};
