'use client';

import React from 'react';
import dynamic from 'next/dynamic';
import { createPortal } from 'react-dom';

const FloatActionsB = dynamic(() => import('./ui-b/float-actions-b'), {
  ssr: false,
});

export const FloatActions: React.FC<{ slug: string }> = ({ slug }) => {
  return (
    <WithPortal>
      <FloatActionsB slug={slug} />
    </WithPortal>
  );
};

const WithPortal = ({
  children,
}: {
  children: React.ReactNode;
}): React.ReactPortal => {
  return createPortal(children, document.body);
};
