import React from 'react';
import Image from 'next/image';
import { v4 as uuidV4 } from 'uuid';
import { Title } from '@shared/ui/typography';
import { Builder } from '@shared/types/builder';
import { blurDataURL } from '@shared/constants';
import igIcon from '@shared/icons/instagram.svg';
import { onAddItem, useDispatch } from '@shared/redux';
import ImageWithFallback from '@shared/ui/image-with-fallback';
import { largeSize } from '@shared/constants/builderCardSizes';
import fallback from '@shared/icons/instagram-profile-image.svg';
import { abbreviateNumber } from '@shared/utils/abbreviateNumber';
import { ComponentNames } from '@shared/constants/componentNames';
import { SocialProfileTarget } from '@widgets/mkit-tour/ui/social-profile-target';
import { CardHelper } from '@widgets/mediakit/components-drawer/components/CardHelper';
import {
  imageStyles,
  ProfileCard,
  PostsContainer,
  ImageContainer,
  HandleContainer,
  ProfileCardBody,
  ProfileCardHead,
  ProfileCardHeadExtra,
} from './styles';
import { useLocalPostHog } from '@shared/hooks/useLocalPostHog';
import { PosthogEvents } from '@shared/constants/posthog-events';

const keys = [
  uuidV4(),
  uuidV4(),
  uuidV4(),
  uuidV4(),
  uuidV4(),
  uuidV4(),
  uuidV4(),
  uuidV4(),
  uuidV4(),
  uuidV4(),
];

const fallbackPosts = [
  { image: '' },
  { image: '' },
  { image: '' },
  { image: '' },
  { image: '' },
  { image: '' },
];

export function InstagramProfileDemo({
  data,
  size,
}: Builder.DemoComponentProps) {
  const dispatch = useDispatch();
  const posthog = useLocalPostHog();

  let posts = data?.posts?.slice(0, 6);
  if (!posts?.length) {
    posts = fallbackPosts;
  }

  const addSocial = () => {
    dispatch(
      onAddItem({
        w: largeSize.w,
        h: largeSize.h,
        i: ComponentNames.instagram_profile,
      }),
    );

    try {
      posthog?.capture(PosthogEvents.click_any_component, {
        isPro: true,
        component: ComponentNames.instagram_profile,
      });
    } catch (e) {
      console.error('Posthog error:', e);
    }
  };

  return (
    <ProfileCard>
      <SocialProfileTarget />
      <ProfileCardBody
        size={size}
        onClick={addSocial}
        data-w={largeSize.w}
        data-h={largeSize.h}
        data-type={data.name}
      >
        <ProfileCardHead>
          <ProfileCardHeadExtra>
            <Image width={17} height={17} src={igIcon} alt="Instagram" />
            <HandleContainer>
              <Title ellipsis level={5}>
                @{data.handle}
              </Title>
            </HandleContainer>
          </ProfileCardHeadExtra>
          <Title ellipsis level={5}>
            {abbreviateNumber(data.followers)}
          </Title>
        </ProfileCardHead>

        <PostsContainer>
          {posts.map(({ image }, index) => (
            <ImageContainer key={keys[index]}>
              <ImageWithFallback
                fill
                sizes="100% 100%"
                style={imageStyles}
                alt="Instagram post"
                fallbackSrc={fallback}
                src={image?.trim() || fallback}
              />
            </ImageContainer>
          ))}
        </PostsContainer>
      </ProfileCardBody>
      <CardHelper size={size}>Profile</CardHelper>
    </ProfileCard>
  );
}
