'use client';

import React from 'react';
import Link from 'next/link';
import styled from '@emotion/styled';
import useBreakpoint from '@shared/hooks/useBreakpoint';
import { AppRoutes } from '@shared/constants/appRoutes';
import { ButtonCustomSkeleton } from '../ButtonSkeleton';
import { useLocalPostHog } from '@shared/hooks/useLocalPostHog';
import { useUpgradeModal } from '@shared/hooks/useUpgradeModal';
import { PosthogEvents } from '@shared/constants/posthog-events';
import { selectAuthUserHandle, useSelector } from '@shared/redux';
import { Menu, Flex, Button, Select, Popover, Typography } from 'antd';
import { useCheckInternetConnection } from '@shared/hooks/useCheckInternetConnection';
import {
  HomeOutlined,
  PlusOutlined,
  RightOutlined,
  CheckOutlined,
} from '@ant-design/icons';
import './home-button.css';

const boxShadow =
  '0px 1px 2px 0px rgba(255, 255, 255, 0.25) inset, 0px 2px 4px 0px rgba(0, 0, 0, 0.25)';

const HomeButtonContainer = styled('div')(() => ({
  boxShadow,
  height: 40,
  display: 'flex',
  borderRadius: 8,
  paddingInline: 8,
  alignItems: 'center',
  transition: 'all 0.3s ease',
  color: 'rgba(255, 255, 255, 0.88)',
  backgroundColor: 'rgba(255, 255, 255, 0.24)',
  border: '1px solid rgba(255, 255, 255, 0.15)',
  '&:hover': {
    borderColor: 'rgba(255, 255, 255, 0.25)',
    backgroundColor: 'rgba(255, 255, 255, 0.35)',
    boxShadow:
      '0px 2px 4px 0px rgba(255, 255, 255, 0.35) inset, 0px 3px 6px 0px rgba(0, 0, 0, 0.35)',
  },
  '.mobile-menu': {
    display: 'none',
  },
  '.desktop-menu': {
    display: 'flex',
  },
  '@media (max-width: 768px)': {
    border: 'none',
    paddingInline: 0,
    boxShadow: 'none',
    borderRadius: 12,
    backgroundColor: 'transparent',
    '.mobile-menu': {
      display: 'flex',
    },
    '.desktop-menu': {
      display: 'none',
    },
  },
}));

const menuStyle = {
  maxWidth: 200,
  borderRight: 'none',
};

const selectStyle = {
  maxWidth: 200,
};

const dropdownStyle = {
  width: 200,
};

const ButtonSkeleton = () => {
  const is768 = useBreakpoint(768);
  const style = {
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    width: is768 ? 40 : 204,
    minWidth: is768 ? 'unset' : void 0,
  };

  return <ButtonCustomSkeleton style={style} />;
};

export const HomeButton: React.FC<{ showSkeleton?: boolean }> = ({
  showSkeleton,
}) => {
  useCheckInternetConnection();
  const posthog = useLocalPostHog();
  const { handleOpen } = useUpgradeModal();
  const handle = useSelector(selectAuthUserHandle);

  const options = [
    {
      label: handle,
      value: handle,
    },
  ];

  const content = (
    <Menu
      style={menuStyle}
      items={[
        {
          key: handle!,
          label: <Typography.Text>{handle}</Typography.Text>,
          icon: <CheckOutlined style={{ color: '#5956E9' }} />,
        },
        {
          key: 'divider',
          type: 'divider',
        },
        {
          key: 'back-to-home',
          icon: <HomeOutlined />,
          label: <Link href={AppRoutes.home}>Back to Home</Link>,
          onClick: () => {
            try {
              posthog?.capture(PosthogEvents.HomeClicked);
            } catch (e) {
              console.error('Posthog error:', e);
            }
          },
        },
        {
          key: 'create-new',
          label: (
            <Typography.Text
              style={{
                color: '#5956E9',
                fontSize: 'inherit',
                fontWeight: 'inherit',
              }}
            >
              Create New
            </Typography.Text>
          ),
          onClick: () => {
            handleOpen('mediakit', {
              from: 'builder_create_new_button',
            });

            try {
              posthog?.capture(PosthogEvents.CreateNewClicked);
            } catch (e) {
              console.error('Posthog error:', e);
            }
          },
          icon: <PlusOutlined style={{ color: '#5956E9' }} />,
        },
      ]}
      selectedKeys={[handle!]}
    />
  );

  const desktopContent = (
    <Menu
      style={menuStyle}
      items={[
        {
          key: handle!,
          label: <Typography.Text>{handle}</Typography.Text>,
          icon: <CheckOutlined style={{ color: '#5956E9' }} />,
        },
        {
          key: 'divider',
          type: 'divider',
        },
        {
          key: 'create-new',
          label: (
            <Typography.Text
              style={{
                color: '#5956E9',
                fontSize: 'inherit',
                fontWeight: 'inherit',
              }}
            >
              Create New
            </Typography.Text>
          ),
          onClick: () => {
            handleOpen('mediakit', {
              from: 'builder_create_new_button',
            });

            try {
              posthog?.capture(PosthogEvents.CreateNewClicked);
            } catch (e) {
              console.error('Posthog error:', e);
            }
          },
          icon: <PlusOutlined style={{ color: '#5956E9' }} />,
        },
      ]}
      selectedKeys={[handle!]}
    />
  );

  const mobileButtonOnClick = () => {
    try {
      posthog?.capture(PosthogEvents.HomeClicked);
    } catch (e) {
      console.error('Posthog error:', e);
    }
  };

  const desktopButtonOnClick = () => {
    try {
      posthog?.capture(PosthogEvents.HomeClicked);
    } catch (e) {
      console.error('Posthog error:', e);
    }
  };

  return showSkeleton ? (
    <ButtonSkeleton />
  ) : (
    <HomeButtonContainer>
      <Flex align="center" className="mobile-menu">
        <Popover
          content={content}
          trigger={['click']}
          placement="bottomLeft"
          styles={{ body: { padding: 3 } }}
        >
          <Button
            size="large"
            className="home-button"
            style={{ borderRadius: 12 }}
            icon={
              <HomeOutlined style={{ color: 'rgba(255, 255, 255, 0.88)' }} />
            }
            onClick={mobileButtonOnClick}
          />
        </Popover>
      </Flex>

      <Flex align="center" className="desktop-menu">
        <Link href={AppRoutes.home} onClick={desktopButtonOnClick}>
          <Button
            type="text"
            className="home-button"
            icon={
              <HomeOutlined style={{ color: 'rgba(255, 255, 255, 0.88)' }} />
            }
          />
        </Link>
        <RightOutlined style={{ color: 'rgba(255, 255, 255, 0.88)' }} />
        <Popover
          trigger={['click']}
          placement="bottomLeft"
          content={desktopContent}
          styles={{ body: { padding: 3 } }}
        >
          <Select
            size="small"
            open={false}
            value={handle}
            options={options}
            style={selectStyle}
            variant="borderless"
            dropdownStyle={dropdownStyle}
            className="header-home-button-select"
          />
        </Popover>
      </Flex>
    </HomeButtonContainer>
  );
};
