'use client';

import React from 'react';
import { Flex, Tag } from 'antd';
import { hexToRgb } from '@shared/utils/hexToRgb';
import { defaultEndColor } from '@shared/constants';
import { CloseCircleFilled } from '@ant-design/icons';
import { Categories } from '@shared/types/categories';
import { useOnboardingMutation } from '@shared/services/auth';
import { IProfile } from '@widgets/mediakit/MediaKit/Profile';
import { fontVariants } from '@shared/constants/fontVariants';
import { textContrastChecker } from '@shared/utils/textContrastChecker';
import {
  useSelector,
  selectCategories,
  selectMediakitTheme,
  selectCategoriesHasSelected,
} from '@shared/redux';

let tagStyles: React.CSSProperties = {
  margin: 0,
  gap: '0.4rem',
  border: 'none',
  display: 'flex',
  height: '4.4rem',
  color: '#5956E9',
  fontSize: '1.4rem',
  alignItems: 'center',
  borderRadius: '0.8rem',
  justifyContent: 'center',
  backgroundColor: 'rgba(0, 0, 0, 0.05)',
};

export const Tags: React.FC<{
  categories?: any[];
  isEditing?: boolean;
  setProfileData: React.Dispatch<React.SetStateAction<IProfile>>;
}> = (props) => {
  const { isEditing, categories, setProfileData } = props;

  const [onboarding] = useOnboardingMutation();
  const theme = useSelector(selectMediakitTheme);
  const allCategories = useSelector(selectCategories);
  const categoriesHasSelected = useSelector(selectCategoriesHasSelected);

  const allCategoriesObject = allCategories.reduce<
    Record<string, Categories.Category>
  >((acc, curr) => {
    acc[`${curr.value}`] = curr;

    return acc;
  }, {});

  function remove(index: number) {
    setProfileData((prevState) => {
      const categories = [...(prevState?.categories || [])];

      categories?.splice(index, 1);

      return {
        ...prevState,
        categories,
      };
    });

    if (!categoriesHasSelected) {
      onboarding({ step: 'general_info.categories' });
    }
  }

  const { r, g, b } = hexToRgb(theme?.primary_color || defaultEndColor);
  const buttonTextColor = textContrastChecker(r, g, b);

  if (buttonTextColor === '#ffffff') {
    tagStyles = {
      ...tagStyles,
      color: theme?.primary_color,
    };
  } else {
    tagStyles = {
      ...tagStyles,
      color: '#000000',
    };
  }

  if (isEditing) {
    return categories?.map(({ value }, index) => {
      const onClose = () => remove(index);
      const tag = allCategoriesObject[value];

      return (
        <Tag
          closable
          style={tagStyles}
          onClose={onClose}
          key={tag?.value || value}
          className={fontVariants.opt3}
          closeIcon={<CloseCircleFilled style={{ fontSize: 12 }} />}
        >
          {tag?.label || value}
        </Tag>
      );
    });
  }

  if (!categories?.length) {
    return null;
  }

  return (
    <Flex wrap align="center" justify="center" gap={8}>
      {categories.map(({ value }) => {
        const tag = allCategoriesObject[value];

        return (
          <Tag
            key={tag?.value || value}
            className={fontVariants.opt3}
            style={{ ...tagStyles, height: '2.4rem', borderRadius: 4 }}
          >
            {tag?.label || value}
          </Tag>
        );
      })}
    </Flex>
  );
};
