import React from 'react';
import { Head } from '../Head';
import * as Groups from '../Groups';
import { Box } from '@shared/ui/box';
import { CSSObject } from '@emotion/styled';
import { breakpoints } from '@shared/theme';
import { BodyContainer } from '@shared/ui/body-container';
import { useOnboardingMutation } from '@shared/services/auth';
import { ComponentsFilters } from '@shared/constants/componentsFilters';
import {
  onAddItem,
  useDispatch,
  useSelector,
  selectSelectedFilter,
  selectComponentsIsSelected,
} from '@shared/redux';
import { PosthogEvents } from '@shared/constants/posthog-events';
import { useLocalPostHog } from '@shared/hooks/useLocalPostHog';

const boxStyles: CSSObject = {
  gap: '3.2rem',
  height: '100%',
  display: 'flex',
  overflowY: 'auto',
  flexDirection: 'column',
  paddingInline: '1.6rem',
  [`@media (max-width: ${breakpoints.md})`]: {
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    paddingInline: '1rem',
  },
};

const Body: React.FC<{
  slug: string;
  username: string;
}> = (props) => {
  const dispatch = useDispatch();
  const posthog = useLocalPostHog();
  const [onboarding] = useOnboardingMutation();

  const selectedFilter = useSelector(selectSelectedFilter);
  const componentsIsSelected = useSelector(selectComponentsIsSelected);

  const filtersMapping = React.useMemo(() => {
    const handleOnAddItem = (e: React.MouseEvent<HTMLDivElement>) => {
      const type = e.currentTarget.dataset.type;

      try {
        posthog?.capture(PosthogEvents.click_any_component, {
          component: type,
        });
      } catch (e) {
        console.error('Posthog error:', e);
      }

      const w = Number(e.currentTarget.dataset.w || 2);
      const h = Number(e.currentTarget.dataset.h || 4);

      console.log(w, h);

      if (type) {
        dispatch(onAddItem({ w, h, i: type }));

        if (!componentsIsSelected) {
          onboarding({ step: 'basics.profile' });
        }
      }
    };

    return {
      [ComponentsFilters.allBasics]: (
        <Groups.Basics
          slug={props.slug}
          username={props.username}
          onAddItem={handleOnAddItem}
        />
      ),
      [ComponentsFilters.allTikTok]: (
        <Groups.TikTokAccount onAddItem={handleOnAddItem} />
      ),
      [ComponentsFilters.allYouTube]: (
        <Groups.YouTubeAccount onAddItem={handleOnAddItem} />
      ),
      [ComponentsFilters.allInstagram]: (
        <Groups.InstagramAccount onAddItem={handleOnAddItem} />
      ),
      default: (
        <Groups.Basics
          slug={props.slug}
          username={props.username}
          onAddItem={handleOnAddItem}
        />
      ),
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, onboarding, props.slug, props.username, componentsIsSelected]);

  const component = filtersMapping[selectedFilter || 'default'];

  return (
    <BodyContainer height="100%">
      <Head />
      <Box boxStyles={boxStyles}>{component}</Box>
    </BodyContainer>
  );
};

export const DrawerBody = React.memo(Body);
