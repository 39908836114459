import React from 'react';
import { ConfigProvider, Skeleton } from 'antd';
import { SkeletonButtonProps } from 'antd/lib/skeleton/Button';

export const ButtonCustomSkeleton: React.FC<SkeletonButtonProps> = (props) => {
  return (
    <ConfigProvider
      theme={{
        components: {
          Skeleton: {
            gradientToColor: 'rgba(255, 255, 255, 0.25)',
            // colorGradientEnd: 'rgba(255, 255, 255, 0.25)',
            gradientFromColor: 'rgba(255, 255, 255, 0.16)',
          },
        },
      }}
    >
      <Skeleton.Button active size="large" {...props} />
    </ConfigProvider>
  );
};
