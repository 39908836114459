'use client';

import React from 'react';
import { Skeleton } from 'antd';
import { Text } from '@shared/ui/typography';
import { PlusOutlined } from '@ant-design/icons';
import fallback from '@shared/icons/fallback.png';
import { AvatarUploader } from '../AvatarUploader';
import { profileSizes } from '@shared/constants/profileSizes';
import ImageWithFallback from '@shared/ui/image-with-fallback';
import { blurDataURL, profileAvatarIndex } from '@shared/constants';
import { ProfileActions } from '@widgets/mediakit/MediaKit/Common/Actions/ui/profile-actions';
import {
  useDispatch,
  useSelector,
  selectAvatar,
  selectActiveItem,
  onChangeActiveItem,
  selectAvatarIsLoading,
} from '@shared/redux';
import {
  ImageWrapper,
  getImageStyles,
  avatarUploaderStyles,
  ProfileAvatarContainer,
} from './styled';

export const ProfileAvatar: React.FC<{
  slug: string;
  username: string;
  isEditing?: boolean;
  showSkeleton?: boolean;
  styles: React.CSSProperties;
}> = ({ slug, styles, username, isEditing, showSkeleton }) => {
  const dispatch = useDispatch();

  const avatar = useSelector(selectAvatar);
  const activeItem = useSelector(selectActiveItem);
  const isLoading = useSelector(selectAvatarIsLoading);

  const hasAvatar = !avatar?.includes('ui-avatars');

  const selected = activeItem === profileAvatarIndex;

  React.useEffect(() => {
    const resizeMobileTarget = document.getElementById('resizeMobileTarget');
    if (!resizeMobileTarget) {
      return;
    }

    if (isEditing && selected) {
      resizeMobileTarget.style.zIndex = '149';
    } else {
      resizeMobileTarget.style.removeProperty('zIndex');
    }
  }, [selected, isEditing]);

  const setSelected = () => {
    dispatch(onChangeActiveItem(profileAvatarIndex));
  };

  const activeSize = `${styles.width}${styles.height}` as profileSizes;

  return (
    <ProfileAvatarContainer
      selected={selected}
      id="profileAvatarContainer"
      style={isEditing ? { zIndex: 1501 } : void 0}
      onClick={hasAvatar && !isLoading ? setSelected : undefined}
    >
      <ImageWrapper>
        {isLoading || showSkeleton ? (
          <Skeleton.Image style={getImageStyles(false, styles)} active />
        ) : hasAvatar ? (
          <ImageWithFallback
            quality={100}
            alt={username}
            placeholder="blur"
            src={avatar as string}
            fallbackSrc={fallback}
            crossOrigin="anonymous"
            blurDataURL={blurDataURL}
            width={styles.width as number}
            height={styles.height as number}
            style={getImageStyles(selected, styles)}
          />
        ) : (
          <AvatarUploader
            slug={slug}
            username={username}
            style={avatarUploaderStyles}
          >
            <div>
              <Text type="secondary">
                <PlusOutlined />
              </Text>
              <br />
              <Text type="secondary">Picture</Text>
            </div>
          </AvatarUploader>
        )}
        {selected && !isLoading && (
          <ProfileActions
            slug={slug}
            username={username}
            activeSize={activeSize}
          />
        )}
      </ImageWrapper>
    </ProfileAvatarContainer>
  );
};
