import React from 'react';
import { App, Modal, Switch, Typography } from 'antd';
import useBreakpoint from '@shared/hooks/useBreakpoint';
import { selectAuthUserDeactivated, useSelector } from '@shared/redux';
import {
  useActivateAccountMutation,
  useDeactivateAccountMutation,
} from '@shared/services/account';
import styles from './deactivated.module.css';

export const Deactivated: React.FC = () => {
  const is375 = useBreakpoint(375);
  const { notification } = App.useApp();
  const isDeactivated = useSelector(selectAuthUserDeactivated);

  const [activateAccount, activateRes] = useActivateAccountMutation();
  const [deactivateAccount, deactivateRes] = useDeactivateAccountMutation();

  const handleOnChange = async (checked: boolean) => {
    if (checked) {
      try {
        await activateAccount().unwrap();
      } catch (error) {
        console.error(error);

        notification.error({
          message: `An error occurred while activating your Media Kit. Please try again later.`,
        });
      }
    } else {
      try {
        await deactivateAccount().unwrap();
      } catch (error) {
        console.error(error);

        notification.error({
          message: `An error occurred while deactivating your Media Kit. Please try again later.`,
        });
      }
    }
  };

  const isLoading = activateRes.isLoading || deactivateRes.isLoading;

  return (
    <Modal
      footer={null}
      closable={false}
      open={isDeactivated}
      title="Reactivate Media Kit"
      width={!is375 ? 360 : void 0}
    >
      <div className={styles.body}>
        <Typography.Text>
          Your media kit is currently deactivated.
          <br />
          Reactivate it to make it visible to others.
        </Typography.Text>
      </div>

      <div className={styles.footer}>
        <Typography.Text strong>Reactivate Media Kit</Typography.Text>

        <Switch
          loading={isLoading}
          checked={!isDeactivated}
          onChange={handleOnChange}
        />
      </div>
    </Modal>
  );
};
