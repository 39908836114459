'use client';

import React from 'react';
import styles from './resize-indicator.module.css';

export const ResizeIndicator: React.FC<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
> = (props) => {
  return (
    <div {...props} className={styles.container}>
      <div className={styles.resizeIndicator} />
    </div>
  );
};
