'use client';

import React from 'react';
import { Button, Input } from 'antd';
import { onUpdateAuthUser, useDispatch } from '@shared/redux';
import { useLocalPostHog } from '@shared/hooks/useLocalPostHog';
import { PosthogEvents } from '@shared/constants/posthog-events';
import { getTikTokUsername } from '@shared/utils/get-tik-tok-username';
import { useFetchComponentsMutation } from '@shared/services/mediakit';
import { useAddSocialAccountsMutation } from '@shared/services/profile';
import {
  Control,
  useForm,
  useWatch,
  Controller,
  useFormState,
} from 'react-hook-form';
import styles from './tik-tok-input.module.css';

interface IFormValues {
  tiktok: string;
}

export const TikTokInput: React.FC = () => {
  const dispatch = useDispatch();
  const posthog = useLocalPostHog();
  const [fetchComponents] = useFetchComponentsMutation();
  const [storeSocialAccounts] = useAddSocialAccountsMutation();
  const containerRef = React.useRef<HTMLDivElement | null>(null);

  const { control, handleSubmit } = useForm<IFormValues>({
    defaultValues: { tiktok: '' },
  });

  React.useEffect(() => {
    containerRef.current?.scrollIntoView();
  }, []);

  const onSubmit = async (formValues: IFormValues) => {
    try {
      let tiktok = getTikTokUsername(formValues.tiktok);

      if (!tiktok) {
        tiktok = formValues.tiktok;
      }

      await storeSocialAccounts({ tiktok }).unwrap();

      try {
        posthog?.capture(PosthogEvents.enter_handle_tt);
      } catch (e) {
        console.error('Posthog error:', e);
      }

      await fetchComponents();

      dispatch(onUpdateAuthUser({ tiktok }));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <div ref={containerRef}>
        <div className={styles.card}>
          <div className={styles.formItem}>
            <span className={styles.text}>
              Enter your TikTok username to attach your account and access your
              data.
            </span>
            <Controller
              name="tiktok"
              control={control}
              render={({ field }) => (
                <Input
                  prefix="@"
                  size="large"
                  placeholder="Enter Your TikTok Username"
                  {...field}
                />
              )}
            />
          </div>

          <SubmitButton control={control} />
        </div>
      </div>
    </form>
  );
};

const SubmitButton: React.FC<{ control: Control<IFormValues> }> = ({
  control,
}) => {
  const { isSubmitting } = useFormState({ control });
  const tiktok = useWatch({ control, name: 'tiktok' });

  return (
    <Button
      block
      size="large"
      type="primary"
      htmlType="submit"
      loading={isSubmitting}
      className={styles.button}
      disabled={!tiktok?.trim()}
    >
      Save
    </Button>
  );
};
