import React from 'react';
import { ProBadge } from '@shared/ui/pro-badge';
import { componentsMap } from './componentsMap';
import { DemoCardSize } from '@shared/constants';
import { TikTokInput } from './ui/tik-tok-input';
import { Connect } from './ui/connect';
import { useUpgradeModal } from '@shared/hooks/useUpgradeModal';
import { SocialComponentsContainer } from '@shared/ui/demo-components-container';
import {
  useSelector,
  selectTikTokUsername,
  selectTikTokAccountComponents,
} from '@shared/redux';
import { PosthogEvents } from '@shared/constants/posthog-events';
import { useLocalPostHog } from '@shared/hooks/useLocalPostHog';

const wrapperStyle: React.CSSProperties = {
  width: 'fit-content',
  position: 'relative',
  display: 'inline-flex',
};

const Body: React.FC<{
  onAddItem: (e: React.MouseEvent<HTMLDivElement>) => void;
}> = ({ onAddItem }) => {
  const posthog = useLocalPostHog();
  const { handleOpen } = useUpgradeModal();
  const components = useSelector(selectTikTokAccountComponents);
  const containerRef = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    containerRef.current?.scrollIntoView();
  }, []);

  return (
    <div>
      <div ref={containerRef}>
        <Connect />
      </div>
      <SocialComponentsContainer>
        {components.map((componentData, index) => {
          const Card = componentsMap[componentData.name];
          const isPro = componentData.access === 'pro';

          let click = onAddItem;

          if (isPro) {
            click = () => {
              handleOpen('component', {
                seeAll: true,
                from: componentData.name,
              });

              try {
                posthog?.capture(PosthogEvents.click_any_component, {
                  isPro: true,
                  component: componentData.name,
                });
              } catch (e) {
                console.error('Posthog error:', e);
              }
            };
          }

          return !!Card ? (
            <div key={index} style={wrapperStyle}>
              <ProBadge isPro={isPro} />
              <Card
                onAddItem={click}
                data={componentData}
                size={DemoCardSize.large}
              />
            </div>
          ) : null;
        })}
      </SocialComponentsContainer>
    </div>
  );
};

export const TikTokAccountGroup: React.FC<{
  onAddItem: (e: React.MouseEvent<HTMLDivElement>) => void;
}> = ({ onAddItem }) => {
  const hasTikTok = !!useSelector(selectTikTokUsername);

  return hasTikTok ? <Body onAddItem={onAddItem} /> : <TikTokInput />;
};
