'use client';

import React from 'react';
import { Typography } from 'antd';
import { InstagramButton } from '@shared/ui/instagram-button';
import { useLocalPostHog } from '@shared/hooks/useLocalPostHog';
import { PosthogEvents } from '@shared/constants/posthog-events';
import { ConnectedInstagram } from '@features/connect-instagram';
import { useInstagramDisconnectMutation } from '@shared/services/instagram';
import {
  useDispatch,
  useSelector,
  onUpdateAuthUser,
  selectAuthUserLinkedAccounts,
} from '@shared/redux';
import styles from './connect.module.css';

const textStyle: React.CSSProperties = { color: 'rgba(255, 255, 255, 0.88)' };

export const Connect: React.FC = () => {
  const dispatch = useDispatch();
  const posthog = useLocalPostHog();
  const [loading, setLoading] = React.useState(false);
  const [tiktokInstagram] = useInstagramDisconnectMutation();
  const linkedAccounts = useSelector(selectAuthUserLinkedAccounts);

  const onDisconnect = async () => {
    setLoading(true);

    try {
      await tiktokInstagram().unwrap();

      try {
        posthog?.capture(PosthogEvents.disconnect_ig);
      } catch (e) {
        console.error('Posthog error:', e);
      }

      dispatch(
        onUpdateAuthUser({
          instagram: void 0,
          linked_accounts: {
            ...linkedAccounts,
            instagram: false,
          },
        }),
      );
    } catch (e) {
      console.error(e);
    }

    setLoading(false);
  };

  return !linkedAccounts?.instagram ? (
    <div className={styles.card}>
      <Typography.Text style={textStyle}>
        To receive your data, please connect your Instagram account.
      </Typography.Text>

      <ConnectedInstagram />
    </div>
  ) : (
    <div className={styles.card}>
      <Typography.Text style={textStyle}>
        To receive your data, please keep your Instagram account connected.
      </Typography.Text>
      <InstagramButton
        block
        size="large"
        type="primary"
        loading={loading}
        onClick={onDisconnect}
      >
        Disconnect Instagram
      </InstagramButton>
    </div>
  );
};
