'use client';

import { Select } from 'antd';
import styled from '@emotion/styled';
import { prefixCls } from '@shared/theme';
import { CSSObject } from '@emotion/styled';

export const LogoContainer = styled('div')(() => ({
  display: 'flex',
  position: 'relative',
}));

export const BorderedSelect = styled(Select, {
  shouldForwardProp: (propName) => propName !== 'selectSelector',
})<{
  selectSelector?: CSSObject;
}>(({ selectSelector }) => ({
  [`&.${prefixCls}-select`]: {
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    [`.${prefixCls}-select-selector`]: {
      textAlign: 'left',
    },
  },
  [`.${prefixCls}-select-focused:where(&).${prefixCls}-select-outlined:not(.${prefixCls}-select-disabled):not(.${prefixCls}-select-customize-input):not(.${prefixCls}-pagination-size-changer) .${prefixCls}-select-selector`]:
    { ...selectSelector },
  [`:where(&).${prefixCls}-select-outlined:not(.${prefixCls}-select-disabled):not(.${prefixCls}-select-customize-input):not(.${prefixCls}-pagination-size-changer):hover .${prefixCls}-select-selector`]:
    { ...selectSelector },
}));

export const CountrySelectContainer = styled('div')(() => ({
  gap: '.8rem',
  display: 'flex',
  alignItems: 'center',
}));
