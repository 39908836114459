export const getYouTubeUsername = (url: string): string | null => {
  const regex =
    /(?:http(?:s)?:\/\/)?(?:www\.)?youtube\.com\/(?:user\/|channel\/|c\/|@)?([a-zA-Z0-9._-]+)/;
  const match = url.match(regex);
  return match ? match[1] : null;
};

export const youtubeValidationRules = (username: string) => {
  const regex = /^[a-zA-Z0-9._-]+$/;
  username = username?.trim() || '';

  if (!username) {
    return true;
  }

  if (getYouTubeUsername(username)) {
    return true;
  }

  if (username.length < 2) {
    return 'The youtube field format is invalid.';
  }

  if (!regex.test(username)) {
    return 'The youtube field format is invalid.';
  }

  return true;
};
