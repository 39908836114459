import React from 'react';
import Link from 'next/link';
import { Container } from './container';
import { avatarSizeMD } from './constants';
import { DropAvatar } from './drop-avatar';
import { useRouter } from 'next/navigation';
import { AvatarLabel } from './avatar-label';
import { MenuInfo } from 'rc-menu/lib/interface';
import { useTidio } from '@shared/hooks/useTidio';
import { CharsContainer } from './chars-container';
import { LogoutOutlined } from '@ant-design/icons';
import useBreakpoint from '@shared/hooks/useBreakpoint';
import { AppRoutes } from '@shared/constants/appRoutes';
import { useLogoutMutation } from '@shared/services/auth';
import { selectAuthUser, useSelector } from '@shared/redux';
import { Badge, Dropdown, MenuProps, Typography } from 'antd';
import { useLocalPostHog } from '@shared/hooks/useLocalPostHog';
import { PosthogEvents } from '@shared/constants/posthog-events';
import { ButtonCustomSkeleton } from '@widgets/mediakit/Header/components/ButtonSkeleton';

const { Text } = Typography;

export const ProfileMenu: React.FC<{
  from?: string;
  bordered?: boolean;
  showSkeleton?: boolean;
}> = ({ bordered, showSkeleton, from }) => {
  const { replace } = useRouter();
  const b768 = useBreakpoint(768);
  const posthog = useLocalPostHog();
  const [logout, res] = useLogoutMutation();
  const authUser = useSelector(selectAuthUser);

  const { tidioChatApiIsOpen, toggleTidioChatApi } = useTidio();

  const [items, setItems] = React.useState<MenuProps['items']>([
    {
      key: 'myAvatar',
      label: <AvatarLabel />,
    },
    {
      key: 'divider_1',
      type: 'divider',
    },
    {
      key: 'login_and_security',
      label: <Link href={AppRoutes.loginAndSecurity}>Login & Security</Link>,
    },
    {
      key: 'divider_2',
      type: 'divider',
    },
    {
      key: 'sign_out',
      label: 'Sign Out',
      icon: <LogoutOutlined />,
    },
  ]);

  React.useEffect(() => {
    if (b768) {
      setItems((prev) => {
        return [
          {
            key: 'myAvatar',
            label: <AvatarLabel />,
          },
          {
            key: 'divider_1',
            type: 'divider',
          },
          {
            key: 'login_and_security',
            label: (
              <Link href={AppRoutes.loginAndSecurity}>Login & Security</Link>
            ),
          },
          {
            key: 'divider_2',
            type: 'divider',
          },
          {
            key: 'contact-us',
            label: 'Contact us',
            onClick: () => {
              if (window.tidioChatApi) {
                if (tidioChatApiIsOpen) {
                  window.tidioChatApi.close();
                  window.tidioChatApi.hide();

                  toggleTidioChatApi(false);
                } else {
                  window.tidioChatApi.show();
                  window.tidioChatApi.open();
                  toggleTidioChatApi(true);
                }
              }
            },
          },
          {
            key: 'privacy-policy',
            label: (
              <a href="https://viralmango.com/privacy-policy/" target="_blank">
                Privacy policy
              </a>
            ),
          },
          {
            key: 'get-help',
            label: 'Get Help',
            onClick: () => {
              if (typeof window !== 'undefined') {
                window.open('/help/ViralMango.pdf', '_blank');
              }
            },
          },
          {
            key: 'divider_3',
            type: 'divider',
          },
          {
            key: 'sign_out',
            label: 'Sign Out',
            icon: <LogoutOutlined />,
          },
        ];
      });
    } else {
      setItems([
        {
          key: 'myAvatar',
          label: <AvatarLabel />,
        },
        {
          key: 'divider_1',
          type: 'divider',
        },
        {
          key: 'login_and_security',
          label: (
            <Link href={AppRoutes.loginAndSecurity}>Login & Security</Link>
          ),
        },
        {
          key: 'divider_2',
          type: 'divider',
        },
        {
          key: 'sign_out',
          label: 'Sign Out',
          icon: <LogoutOutlined />,
        },
      ]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [b768]);

  const chars = authUser?.email?.slice(0, 2)?.toUpperCase();

  React.useEffect(() => {
    if (res.isSuccess) {
      replace(AppRoutes.login);
    }
  }, [replace, res.isSuccess]);

  const onClick = async (info: MenuInfo) => {
    switch (info.key) {
      case 'sign_out': {
        await logout();

        if (typeof window === 'object') {
          window.location.replace(AppRoutes.login);
        }
        break;
      }
    }
  };

  return (
    <Container>
      {!authUser || showSkeleton ? (
        <div style={{ display: 'flex', lineHeight: 1 }}>
          <ButtonCustomSkeleton
            active
            shape="circle"
            size="default"
            style={{ width: 40, height: 40 }}
          />
        </div>
      ) : (
        <Dropdown trigger={['click']} menu={{ items, onClick }}>
          <Badge>
            <DropAvatar
              shape="circle"
              size={avatarSizeMD}
              bordered={bordered}
              src={authUser?.profile_photo_path}
              onClick={() => {
                if (from === 'home') {
                  try {
                    posthog?.capture(PosthogEvents.click_user_db);
                  } catch (e) {
                    console.error('Posthog error:', e);
                  }
                } else {
                  try {
                    posthog?.capture(PosthogEvents.click_user);
                  } catch (e) {
                    console.error('Posthog error:', e);
                  }
                }
              }}
            >
              <CharsContainer>
                <Text color="primary">{chars}</Text>
              </CharsContainer>
            </DropAvatar>
          </Badge>
        </Dropdown>
      )}
    </Container>
  );
};
