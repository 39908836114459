'use client';

import React from 'react';
import { v4 as uuidV4 } from 'uuid';
import styled from '@emotion/styled';
import { IProfile } from '../../index';
import { prefixCls } from '@shared/theme';
import { Builder } from '@shared/types/builder';
import { LocationIcon } from '@shared/ui/Icons';
import { Flex, Skeleton, Typography } from 'antd';
import { fontVariants } from '@shared/constants/fontVariants';
import { socialPlatformsIcons } from '@shared/ui/social-icons';
import { useLocalPostHog } from '@shared/hooks/useLocalPostHog';
import { PosthogEvents } from '@shared/constants/posthog-events';
import { useSelector, selectCountries, selectSocialLinks } from '@shared/redux';
import {
  LogoContainer,
  BorderedSelect,
  CountrySelectContainer,
} from './styled';

const keys = [uuidV4(), uuidV4(), uuidV4()];

const dropdownStyle: React.CSSProperties = { width: 300 };

const CustomTag = styled('span')(() => ({
  width: 44,
  height: 44,
  display: 'flex',
  borderRadius: 8,
  position: 'relative',
  alignItems: 'center',
  justifyContent: 'center',
  border: '1px solid rgba(0, 0, 0, 0.15)',
}));

const SocialSkeleton = styled(Skeleton)(() => ({
  [`&.${prefixCls}-skeleton`]: {
    width: 44,
    height: 44,
    borderRadius: 8,
    [`& > .${prefixCls}-skeleton-content`]: {
      [`& > .${prefixCls}-skeleton-title`]: {
        width: 44,
        height: 44,
        marginBottom: 0,
      },
    },
  },
}));

const TagSkeleton: React.FC = () => {
  return keys.map((uuid) => (
    <SocialSkeleton active loading key={uuid} paragraph={false}>
      <CustomTag />
    </SocialSkeleton>
  ));
};

const ViewModeIcon = styled('div')(() => ({
  width: 32,
  height: 32,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const CountrySkeleton = styled(Skeleton)({
  [`&.${prefixCls}-skeleton`]: {
    width: 100,
    [`& > .${prefixCls}-skeleton-content`]: {
      height: 18,
      [`& > .${prefixCls}-skeleton-title`]: {
        height: 18,
        marginBottom: 0,
      },
    },
  },
});

export const CountrySelect: React.FC<{
  loading?: boolean;
  isEditing?: boolean;
  showSkeleton?: boolean;
  location?: IProfile['location'];
  setProfileData: React.Dispatch<React.SetStateAction<IProfile>>;
}> = (props) => {
  const { isEditing, showSkeleton, location, setProfileData } = props;

  const posthog = useLocalPostHog();
  const selectRef = React.useRef<any>();
  const countries = useSelector(selectCountries);
  const socialLinks = useSelector(selectSocialLinks) || [];

  const filterOption = (
    input: string,
    option?: { label: string; value: string },
  ) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const onSelect = (
    _value: string,
    options: Builder.CurrentMediakit.Location,
  ) => {
    setProfileData((prevState) => ({
      ...prevState,
      location: options,
    }));

    setTimeout(() => selectRef?.current?.blur(), 300);

    if (options.label) {
      try {
        posthog?.capture(PosthogEvents.AddedCountry, {
          country: options.label,
        });
      } catch (e) {
        console.error('Posthog error:', e);
      }
    }
  };

  if (isEditing) {
    return (
      <Flex vertical gap={10}>
        <Flex>
          <Typography.Text strong>Location</Typography.Text>
        </Flex>
        <CountrySelectContainer>
          <LogoContainer>
            <LocationIcon fill="#00000073" />
          </LogoContainer>
          <CountrySkeleton
            paragraph={false}
            active={showSkeleton}
            loading={showSkeleton}
          >
            <BorderedSelect
              showSearch
              ref={selectRef}
              virtual={false}
              autoFocus={false}
              options={countries}
              variant="outlined"
              placeholder="Country"
              onSelect={onSelect as any}
              className={fontVariants.opt3}
              dropdownStyle={dropdownStyle}
              rootClassName="bordered-select"
              filterOption={filterOption as any}
              value={location?.value ? location : null}
            />
          </CountrySkeleton>
        </CountrySelectContainer>
      </Flex>
    );
  }

  if (showSkeleton) {
    return (
      <Flex gap={4} wrap align="center" justify="center">
        <TagSkeleton />
      </Flex>
    );
  }

  return socialLinks.length ? (
    <Flex gap={4} wrap align="center" justify="center">
      {socialLinks?.map((link) => {
        const item = socialPlatformsIcons[link.platform];
        return !!item ? (
          <ViewModeIcon key={link.platform}>
            {item.dark({ style: { width: 20, height: 20, opacity: 0.88 } })}
          </ViewModeIcon>
        ) : null;
      })}
    </Flex>
  ) : null;
};
