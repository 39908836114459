import React from 'react';
import { VideoBox } from './video-box';

export const cover1Desktop = (
  <VideoBox>
    <video
      muted
      autoPlay
      loop={true}
      width="406px"
      height="auto"
      preload="auto"
      controls={false}
      poster={'/videos/1_Desktop.png'}
    >
      <source src="/videos/1_Desktop.mp4" type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  </VideoBox>
);

export const cover1Mobile = (
  <VideoBox>
    <video
      muted
      autoPlay
      loop={true}
      width="406px"
      height="auto"
      preload="auto"
      controls={false}
      poster={'/videos/1_Mobile.png'}
    >
      <source src="/videos/1_Mobile.mp4" type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  </VideoBox>
);

export const cover2Desktop = (
  <VideoBox>
    <video
      muted
      autoPlay
      loop={true}
      width="406px"
      height="auto"
      preload="auto"
      controls={false}
      poster="/videos/2_Desktop.png"
    >
      <source src="/videos/2_Desktop.mp4" type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  </VideoBox>
);

export const cover2Mobile = (
  <VideoBox>
    <video
      muted
      autoPlay
      loop={true}
      width="100%"
      height="auto"
      preload="auto"
      controls={false}
      poster="/videos/2_Mobile.png"
    >
      <source src="/videos/2_Mobile.mp4" type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  </VideoBox>
);
