'use client';

import React from 'react';
import { Divider } from 'antd';
import { createPortal } from 'react-dom';
import { Backdrop } from './components/Backdrop';
import { BioInput } from './components/BioInput';
import { NameInput } from './components/NameInput';
import { AddSocials } from './components/AddSocials';
import { QueryStatus } from '@reduxjs/toolkit/query';
import { ProfileCard } from './components/ProfileCard';
import { SubmitButton } from './components/SubmitButton';
import { ActionButton } from './components/ActionButton';
import { CountrySelect } from './components/CountrySelect';
import { ProfileAvatar } from './components/ProfileAvatar';
import { ProfileCardBody } from './components/ProfileCardBody';
import { useLocalPostHog } from '@shared/hooks/useLocalPostHog';
import { PosthogEvents } from '@shared/constants/posthog-events';
import { CategoriesSelect } from './components/CategoriesSelect';
import {
  useSelector,
  selectAvatar,
  selectProfile,
  selectBuilderPreviewMode,
  selectCreatingQueryStatus,
} from '@shared/redux';
import {
  profileSizes,
  profileAvatarStyles,
} from '@shared/constants/profileSizes';

const dividerStyle: React.CSSProperties = {
  margin: 0,
  right: '2.4rem',
  position: 'relative',
  marginInline: 'auto',
  width: 'calc(100% + 4.8rem)',
};

const Mask = () => {
  return (
    <div
      onClick={() => {
        document
          .getElementById('bioTextAreaTarget')
          ?.scrollIntoView({ behavior: 'smooth' });

        setTimeout(() => {
          document
            .getElementById('saveProfileButton')
            ?.classList.add('animate-wiggle');
        }, 300);

        if (navigator.vibrate) {
          navigator.vibrate(200);
        }
      }}
      style={{
        top: 0,
        left: 0,
        zIndex: 148,
        width: '100%',
        height: '100%',
        position: 'fixed',
      }}
    />
  );
};

export interface IProfile {
  cta?: string;
  bio?: string;
  name?: { value?: string };
  location?: {
    value: string;
    label: string;
  };
  categories?: {
    label: string;
    value: number;
  }[];
  social_links?: {
    value: string;
    platform: number;
  }[];
}

export const Profile: React.FC<{
  slug: string;
  username: string;
  showSkeleton: boolean;
}> = ({ slug, username, showSkeleton }) => {
  const posthog = useLocalPostHog();
  const avatar = useSelector(selectAvatar);
  const profile = useSelector(selectProfile);
  const [isEditing, setIsEditing] = React.useState(false);
  const previewMode = useSelector(selectBuilderPreviewMode);
  const [localLoading, setLocalLoading] = React.useState(true);
  const creatingQueryStatus = useSelector(selectCreatingQueryStatus);

  const [profileData, setProfileData] = React.useState<IProfile>({});

  React.useEffect(() => {
    setProfileData({
      cta: profile.cta,
      bio: profile.bio,
      name: profile.name,
      location: profile.location,
      categories: profile.categories,
      social_links: profile.social_links,
    });

    setLocalLoading(false);
  }, [
    profile.cta,
    profile.bio,
    profile.name,
    profile.location,
    profile.categories,
    profile.social_links,
  ]);

  showSkeleton = showSkeleton || localLoading;
  let loading = creatingQueryStatus !== QueryStatus.fulfilled;

  const hasAvatar = !avatar?.includes('ui-avatars');
  const avatarStyles = profileAvatarStyles[profile.avatar_shape!] || {};

  const { width, height } = avatarStyles;
  const activeSize = `${width}${height}` as profileSizes;

  const onCancel = () => {
    setProfileData({
      cta: profile.cta,
      bio: profile.bio,
      name: profile.name,
      location: profile.location,
      categories: profile.categories,
      social_links: profile.social_links,
    });

    setIsEditing(false);

    const builderFloatActions = document.getElementById('builderFloatActions');
    if (builderFloatActions) {
      builderFloatActions.style.removeProperty('display');
    }

    try {
      posthog?.capture(PosthogEvents.edit_cancel);
    } catch (e) {
      console.error('Posthog error:', e);
    }
  };

  return (
    <ProfileCard
      className={previewMode}
      style={isEditing ? { zIndex: 149 } : void 0}
    >
      <ProfileCardBody
        activeSize={hasAvatar ? activeSize : profileSizes.s160160}
      >
        <NameInput
          loading={loading}
          isEditing={isEditing}
          name={profileData.name}
          showSkeleton={showSkeleton}
          setProfileData={setProfileData}
        />

        {isEditing && <Divider style={dividerStyle} />}

        <CountrySelect
          loading={loading}
          isEditing={isEditing}
          showSkeleton={loading}
          location={profileData.location}
          setProfileData={setProfileData}
        />

        {isEditing && <Divider style={dividerStyle} />}

        <AddSocials
          isEditing={isEditing}
          showSkeleton={loading}
          setProfileData={setProfileData}
          socialLinks={profileData.social_links}
        />

        {isEditing && <Divider style={dividerStyle} />}

        <CategoriesSelect
          isEditing={isEditing}
          showSkeleton={loading}
          setProfileData={setProfileData}
          categories={profileData.categories}
        />

        {isEditing && <Divider style={dividerStyle} />}

        <BioInput
          bio={profileData.bio}
          isEditing={isEditing}
          showSkeleton={loading}
          setProfileData={setProfileData}
        />

        {isEditing && <Divider style={dividerStyle} />}

        <ActionButton
          cta={profileData.cta}
          isEditing={isEditing}
          showSkeleton={loading}
          setProfileData={setProfileData}
        />

        <Divider style={{ ...dividerStyle, marginBlock: !isEditing ? 4 : 0 }} />

        <SubmitButton
          onCancel={onCancel}
          isEditing={isEditing}
          showSkeleton={loading}
          profileData={profileData}
          setIsEditing={setIsEditing}
        />
      </ProfileCardBody>
      <div>
        <ProfileAvatar
          slug={slug}
          username={username}
          styles={avatarStyles}
          isEditing={isEditing}
          showSkeleton={showSkeleton}
        />
        <Backdrop />
      </div>
      {isEditing && createPortal(<Mask />, document.body)}
    </ProfileCard>
  );
};
