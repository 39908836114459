'use client';

import React from 'react';
import { Typography } from 'antd';
import { TikTokButton } from '@shared/ui/tik-tok-button';
import { ConnectedTikTok } from '@features/connect-tik-tok';
import { useLocalPostHog } from '@shared/hooks/useLocalPostHog';
import { PosthogEvents } from '@shared/constants/posthog-events';
import { useTiktokDisconnectMutation } from '@shared/services/tiktok';
import {
  useDispatch,
  useSelector,
  onUpdateAuthUser,
  selectAuthUserLinkedAccounts,
} from '@shared/redux';
import styles from './connect.module.css';

const textStyle: React.CSSProperties = { color: 'rgba(255, 255, 255, 0.88)' };

export const Connect: React.FC = () => {
  const dispatch = useDispatch();
  const posthog = useLocalPostHog();
  const [loading, setLoading] = React.useState(false);
  const [tiktokDisconnect] = useTiktokDisconnectMutation();
  const linkedAccounts = useSelector(selectAuthUserLinkedAccounts);

  const onDisconnect = async () => {
    setLoading(true);

    try {
      await tiktokDisconnect().unwrap();

      try {
        posthog?.capture(PosthogEvents.disconnect_tt);
      } catch (e) {
        console.error('Posthog error:', e);
      }

      dispatch(
        onUpdateAuthUser({
          tiktok: void 0,
          linked_accounts: {
            ...linkedAccounts,
            tiktok: false,
          },
        }),
      );
    } catch (e) {
      console.error(e);
    }

    setLoading(false);
  };

  return !linkedAccounts?.tiktok ? (
    <div className={styles.card}>
      <Typography.Text style={textStyle}>
        To receive more accurate data, please connect your TikTok account.
      </Typography.Text>

      <ConnectedTikTok />
    </div>
  ) : (
    <div className={styles.card}>
      <Typography.Text style={textStyle}>
        To receive your data, please keep your TikTok account connected.
      </Typography.Text>
      <TikTokButton
        block
        size="large"
        type="primary"
        loading={loading}
        onClick={onDisconnect}
      >
        Disconnect TikTok
      </TikTokButton>
    </div>
  );
};
