'use client';

import React from 'react';
import { DrawerBody } from './ui/drawer-body';
import { MkitDrawer } from '@shared/ui/MkitDrawer';
import { HeightToggler } from './ui/height-toggler';
import useBreakpoint from '@shared/hooks/useBreakpoint';
import { ThemeDrawerMask } from './ui/theme-drawer-mask';
import { ResizeIndicator } from '@/shared/ui/resize-indicator';
import {
  useDispatch,
  useSelector,
  selectDrawerTheme,
  onCloseThemeDrawer,
  selectThemeDrawerHeight,
  selectThemeDrawerIsOpen,
  onUpdateThemeDrawerHeight,
} from '@shared/redux';

interface DrawerProps {
  slug: string;
  username: string;
}

const touchend = () => {
  document.body.style.overflowY = 'auto';
};

const touchstart = () => {
  document.body.style.overflowY = 'hidden';
};

export const ThemeDrawer: React.FC<DrawerProps> = ({ slug, username }) => {
  const dispatch = useDispatch();
  const matches = useBreakpoint(991);
  const drawerTheme = useSelector(selectDrawerTheme);
  const height = useSelector(selectThemeDrawerHeight);
  const panelRef = React.useRef<HTMLDivElement>(null);
  const drawerIsOpen = useSelector(selectThemeDrawerIsOpen);

  const [lastUpdate, setLastUpdate] = React.useState<number>(0);
  const [touchStarted, setTouchStarted] = React.useState<boolean>(false);

  const heightToggler = () => {
    setHeight(height ? '80vh' : '60vh');

    if (!height) {
      document.body.style.overflowY = 'auto';
    } else {
      document.body.style.overflowY = 'hidden';
    }
  };

  const afterOpenChange = (open: boolean) => {
    if (!open) {
      setHeight('80vh');
    }

    if (open) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }

    const drawerWrapper = document.getElementById('theme-drawer');

    if (drawerWrapper) {
      if (open) {
        drawerWrapper.addEventListener('touchend', touchend);
        drawerWrapper.addEventListener('touchstart', touchstart);
      } else {
        drawerWrapper.removeEventListener('touchend', touchend);
        drawerWrapper.removeEventListener('touchstart', touchstart);
      }
    }
  };

  const setHeight = (h: string | undefined) => {
    dispatch(onUpdateThemeDrawerHeight(h));
  };

  const onClose = () => dispatch(onCloseThemeDrawer());

  const onTouchEnd = () => {
    setTouchStarted(false);

    const mkitDrawer = document.querySelector(
      '.mediakit-drawer-content-wrapper',
    );

    mkitDrawer?.classList?.remove('smooth-transition');
    const drawerWrapper = document.getElementById('theme-drawer');

    if (drawerWrapper) {
      drawerWrapper.removeEventListener('touchend', touchend);
      drawerWrapper.removeEventListener('touchstart', touchstart);
    }
  };

  const onTouchStart = () => {
    setTouchStarted(true);

    const mkitDrawer = document.querySelector(
      '.mediakit-drawer-content-wrapper',
    );

    mkitDrawer?.classList?.add('smooth-transition');

    const drawerWrapper = document.getElementById('theme-drawer');

    if (drawerWrapper) {
      drawerWrapper.addEventListener('touchend', touchend);
      drawerWrapper.addEventListener('touchstart', touchstart);
    }
  };

  const onTouchMove = (e: React.TouchEvent) => {
    window.stop();

    e.preventDefault();
    const now = Date.now();

    if (now - lastUpdate < 50) return;

    if (touchStarted) {
      let vh = Math.max(
        document.documentElement.clientHeight || 0,
        window.innerHeight || 0,
      );
      const clientY = vh - e.touches[0].clientY;

      let resizedHeight = Math.round((clientY * 100) / vh);
      if (resizedHeight > 80) {
        resizedHeight = 80;
      } else if (resizedHeight < 40) {
        resizedHeight = 40;
      }

      if (height !== `${resizedHeight}vh`) {
        setHeight(`${resizedHeight}vh`);
      }
    }

    setLastUpdate(now);
  };

  return (
    <>
      <MkitDrawer
        maskClosable
        destroyOnClose
        title="Theming"
        mask={!matches}
        onClose={onClose}
        id="theme-drawer"
        open={drawerIsOpen}
        panelRef={panelRef}
        mkitTheme={drawerTheme}
        styles={{
          mask: { backgroundColor: 'transparent' },
          body: {
            height: '100%',
            display: 'flex',
            paddingInline: 0,
            flexDirection: 'column',
          },
        }}
        paddingXS="0 1rem 2rem 1rem"
        padding="0 2.2rem 2rem 2.2rem"
        style={{ position: 'relative' }}
        afterOpenChange={afterOpenChange}
        width={!matches ? 396 : undefined}
        size={matches ? 'large' : undefined}
        height={matches ? height : undefined}
        placement={matches ? 'bottom' : 'right'}
        classNames={{ wrapper: 'drawer-wrapper' }}
      >
        <ResizeIndicator
          onTouchEnd={onTouchEnd}
          onTouchMove={onTouchMove}
          onTouchStart={onTouchStart}
        />
        <DrawerBody slug={slug} username={username} />
        <HeightToggler onClick={heightToggler} />
      </MkitDrawer>
      <ThemeDrawerMask onClose={onClose} />
    </>
  );
};
