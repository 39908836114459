'use client';

import React from 'react';
import debounce from 'lodash.debounce';
import { prefixCls } from '@shared/theme';
import { Button, Select, Skeleton } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import styled, { CSSObject } from '@emotion/styled';
import { Categories } from '@shared/types/categories';
import { fontVariants } from '@shared/constants/fontVariants';
import { useSelector, selectCategories } from '@shared/redux';
import { IProfile } from '@widgets/mediakit/MediaKit/Profile';
import { useLocalPostHog } from '@shared/hooks/useLocalPostHog';
import { PosthogEvents } from '@shared/constants/posthog-events';

export const StyledSelect = styled(Select, {
  shouldForwardProp: (propName) => propName !== 'selectSelector',
})<{
  selectSelector?: CSSObject;
}>(({ selectSelector }) => ({
  [`&.${prefixCls}-select`]: {
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    [`.${prefixCls}-select-selector`]: {
      textAlign: 'left',
    },
  },
  [`.${prefixCls}-select-focused:where(&).${prefixCls}-select-outlined:not(.${prefixCls}-select-disabled):not(.${prefixCls}-select-customize-input):not(.${prefixCls}-pagination-size-changer) .${prefixCls}-select-selector`]:
    { ...selectSelector },
  [`:where(&).${prefixCls}-select-outlined:not(.${prefixCls}-select-disabled):not(.${prefixCls}-select-customize-input):not(.${prefixCls}-pagination-size-changer):hover .${prefixCls}-select-selector`]:
    { ...selectSelector },
}));

const CategorySkeleton = styled(Skeleton)(() => ({
  [`&.${prefixCls}-skeleton`]: {
    width: 86,
    [`& > .${prefixCls}-skeleton-content`]: {
      height: 32,
      [`& > .${prefixCls}-skeleton-title`]: {
        height: 32,
        marginBottom: 0,
        borderRadius: 99,
      },
    },
  },
}));

export const AddNewTag = React.forwardRef(
  (
    {
      onBlur,
      onSelect,
      disabled,
      showInput,
      inputVisible,
      showSkeleton,
      categories: selected,
    }: {
      disabled?: boolean;
      onBlur: () => void;
      showInput: () => void;
      inputVisible: boolean;
      showSkeleton?: boolean;
      categories: IProfile['categories'];
      onSelect: (
        _value: Categories.Category['value'],
        option?: Categories.Category,
      ) => void;
    },
    ref,
  ) => {
    selected = selected || [];

    const posthog = useLocalPostHog();
    const categories = useSelector(selectCategories);

    if (selected.length >= 5) {
      return null;
    }

    const selectedValues = selected?.map((s) => Number(s.value));

    const options = categories.filter((cat) => {
      return !selectedValues.includes(cat.value);
    });

    const filterOption = (
      input: string,
      option?: { label: string; value: string },
    ) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const onAnimationEnd = (e: React.AnimationEvent<HTMLDivElement>) => {
      e.currentTarget.classList.remove('animate__shakeY');
    };

    const debouncedOnInputKeyDown = debounce((value: string) => {
      try {
        posthog?.capture(PosthogEvents.CategorySearch, {
          category: value,
        });
      } catch (e) {
        console.error('Posthog error:', e);
      }
    }, 400);

    return (
      <>
        <StyledSelect
          autoFocus
          value={[]}
          showSearch
          mode="multiple"
          virtual={false}
          onBlur={onBlur}
          ref={ref as any}
          options={options}
          open={inputVisible}
          onSelect={onSelect as any}
          onInputKeyDown={(e) => {
            if (e.currentTarget.value) {
              debouncedOnInputKeyDown(e.currentTarget.value);
            }
          }}
          filterOption={filterOption as any}
          placeholder="Type category name here"
          style={{ display: inputVisible ? 'block' : 'none' }}
        />
        {showSkeleton ? (
          <CategorySkeleton
            paragraph={false}
            active={showSkeleton}
            loading={showSkeleton}
          />
        ) : (
          <Button
            type="dashed"
            title="Add socials"
            icon={<PlusOutlined />}
            style={{
              width: 44,
              height: 44,
              display: !inputVisible ? 'flex' : 'none',
            }}
            onAnimationEnd={onAnimationEnd}
            onClick={!disabled ? showInput : undefined}
            className={`${fontVariants.opt3} animate__animated`}
            id={!disabled ? 'categoriesSelectTarget' : undefined}
          />
        )}
      </>
    );
  },
);

AddNewTag.displayName = 'AddNewTag';
