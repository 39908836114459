'use client';

import React from 'react';
import styled from '@emotion/styled';
import { IProfile } from '../../index';
import { prefixCls } from '@shared/theme';
import { LocationIcon } from '@shared/ui/Icons';
import { Builder } from '@shared/types/builder';
import { ModalProps } from 'antd/lib/modal/interface';
import useBreakpoint from '@shared/hooks/useBreakpoint';
import { useSelector, selectLocation } from '@shared/redux';
import { MoreOutlined, PlusOutlined } from '@ant-design/icons';
import { useLocalPostHog } from '@shared/hooks/useLocalPostHog';
import { PosthogEvents } from '@shared/constants/posthog-events';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import {
  socialPlatforms,
  socialPlatformsIcons,
  groupedSocialPlatforms,
} from '@shared/ui/social-icons';
import {
  Flex,
  Form,
  Input,
  Modal,
  Button,
  Skeleton,
  Dropdown,
  Typography,
} from 'antd';
import styles from './add-socials.module.css';

interface ISocialLink {
  value: string;
  platform: number;
}

interface IFormValues {
  payment: ISocialLink[];
  linkInBio: ISocialLink[];
  personal: ISocialLink[];
  donation: ISocialLink[];
  streaming: ISocialLink[];
  community: ISocialLink[];
  eCommerce: ISocialLink[];
  socialMedia: ISocialLink[];
  subscriptionBased: ISocialLink[];
}

const LogoContainer = styled('div')(() => ({
  display: 'flex',
  position: 'relative',
}));

const modalStyles: ModalProps['styles'] = {
  body: { padding: 0 },
  content: { padding: 0 },
  header: { padding: '16px 24px' },
};

const modalStyle: ModalProps['style'] = {
  top: 20,
};

const Body = styled('div', {
  shouldForwardProp: (propName) => propName !== 'showMore',
})(({ showMore }: { showMore?: boolean }) => ({
  gap: '1.6rem',
  display: 'flex',
  padding: '1.6rem',
  overflowY: 'auto',
  flexDirection: 'column',
  maxHeight: 'calc(100vh - 280px)',
  [`& > .${prefixCls}-typography:not(:nth-of-type(1))`]: {
    marginTop: '0.8rem',
  },
  ...(!showMore
    ? {
        height: 180,
        overflowY: 'hidden',
      }
    : {}),
}));

const Footer = styled('div')(() => ({
  display: 'flex',
  padding: '1.6rem',
  alignItems: 'center',
  justifyContent: 'center',
}));

const Container = styled('div')(() => ({
  gap: '0.8rem',
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'flex-start',
}));

const InputGroup = styled('div')(() => ({
  gap: '.8rem',
  display: 'flex',
  alignItems: 'center',
}));

const IconText = styled(Typography.Text)(() => ({
  width: '2.4rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const ModalContent: React.FC<{
  defaultValues?: ISocialLink[];
  toggleModal: React.Dispatch<React.SetStateAction<boolean>>;
  setProfileData: React.Dispatch<React.SetStateAction<IProfile>>;
}> = (props) => {
  const { toggleModal, defaultValues, setProfileData } = props;

  const posthog = useLocalPostHog();
  const [showMore, setShowMore] = React.useState<boolean>(false);

  const onlyValues = defaultValues?.reduce<Record<string, boolean>>(
    (acc, current) => {
      acc[current.platform] = true;

      return acc;
    },
    {},
  );

  const payment = groupedSocialPlatforms.payment
    .filter((sp) => !(onlyValues || {})[sp.platform])
    .map(({ platform }) => ({ platform, value: '' }));

  const personal = groupedSocialPlatforms.personal
    .filter((sp) => !(onlyValues || {})[sp.platform])
    .map(({ platform }) => ({ platform, value: '' }));

  const donation = groupedSocialPlatforms.donation
    .filter((sp) => !(onlyValues || {})[sp.platform])
    .map(({ platform }) => ({ platform, value: '' }));

  const streaming = groupedSocialPlatforms.streaming
    .filter((sp) => !(onlyValues || {})[sp.platform])
    .map(({ platform }) => ({ platform, value: '' }));

  const community = groupedSocialPlatforms.community
    .filter((sp) => !(onlyValues || {})[sp.platform])
    .map(({ platform }) => ({ platform, value: '' }));

  const linkInBio = groupedSocialPlatforms.linkInBio
    .filter((sp) => !(onlyValues || {})[sp.platform])
    .map(({ platform }) => ({ platform, value: '' }));

  const eCommerce = groupedSocialPlatforms.eCommerce
    .filter((sp) => !(onlyValues || {})[sp.platform])
    .map(({ platform }) => ({ platform, value: '' }));

  const socialMedia = groupedSocialPlatforms.socialMedia
    .filter((sp) => !(onlyValues || {})[sp.platform])
    .map(({ platform }) => ({ platform, value: '' }));

  const subscriptionBased = groupedSocialPlatforms.subscriptionBased
    .filter((sp) => !(onlyValues || {})[sp.platform])
    .map(({ platform }) => ({ platform, value: '' }));

  const { control, register, handleSubmit } = useForm<IFormValues>({
    defaultValues: {
      payment,
      personal,
      donation,
      streaming,
      community,
      linkInBio,
      eCommerce,
      socialMedia,
      subscriptionBased,
    },
  });

  const { fields: fieldsPayment } = useFieldArray({ control, name: 'payment' });
  const { fields: fieldsPersonal } = useFieldArray({
    control,
    name: 'personal',
  });
  const { fields: fieldsDonation } = useFieldArray({
    control,
    name: 'donation',
  });
  const { fields: fieldsStreaming } = useFieldArray({
    control,
    name: 'streaming',
  });
  const { fields: fieldsCommunity } = useFieldArray({
    control,
    name: 'community',
  });
  const { fields: fieldsLinkInBio } = useFieldArray({
    control,
    name: 'linkInBio',
  });
  const { fields: fieldsECommerce } = useFieldArray({
    control,
    name: 'eCommerce',
  });
  const { fields: fieldsSocialMedia } = useFieldArray({
    control,
    name: 'socialMedia',
  });
  const { fields: fieldsSubscriptionBased } = useFieldArray({
    control,
    name: 'subscriptionBased',
  });

  const onSubmit = (data: IFormValues) => {
    const links = [
      ...data.payment.filter((link) => link.value),
      ...data.linkInBio.filter((link) => link.value),
      ...data.personal.filter((link) => link.value),
      ...data.donation.filter((link) => link.value),
      ...data.streaming.filter((link) => link.value),
      ...data.community.filter((link) => link.value),
      ...data.eCommerce.filter((link) => link.value),
      ...data.socialMedia.filter((link) => link.value),
      ...data.subscriptionBased.filter((link) => link.value),
    ];

    let types;

    if (defaultValues) {
      const newValues = defaultValues.concat(links);

      types = newValues.map((v) => {
        const platform = socialPlatforms.find(
          (sp) => sp.platform === v.platform,
        );
        return platform?.type;
      });

      setProfileData((prevState) => ({
        ...prevState,
        social_links: newValues,
      }));
    } else {
      setProfileData((prevState) => ({
        ...prevState,
        social_links: links,
      }));

      types = links.map((v) => {
        const platform = socialPlatforms.find(
          (sp) => sp.platform === v.platform,
        );
        return platform?.type;
      });
    }

    try {
      posthog?.capture(PosthogEvents.AddedSocials, {
        socials: types,
      });
    } catch (e) {
      console.error('Posthog error:', e);
    }

    toggleModal(false);
  };

  const fieldsLength =
    fieldsPayment.length +
    fieldsPersonal.length +
    fieldsDonation.length +
    fieldsCommunity.length +
    fieldsLinkInBio.length +
    fieldsECommerce.length +
    fieldsStreaming.length +
    fieldsSocialMedia.length +
    fieldsSubscriptionBased.length;

  return (
    <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      <Body showMore={showMore || fieldsLength <= 4}>
        {!!fieldsSocialMedia.length && (
          <>
            <Typography.Text strong>Social Media</Typography.Text>

            {fieldsSocialMedia.map((field, index) => {
              const { dark, placeholder } =
                socialPlatformsIcons[field.platform];

              return (
                <InputGroup key={field.id}>
                  <IconText>
                    {dark({ style: { width: 24, height: 24 } })}
                  </IconText>

                  <Controller
                    control={control}
                    name={`socialMedia.${index}.value`}
                    render={({ field }) => (
                      <Input
                        {...field}
                        size="large"
                        autoComplete="off"
                        placeholder={placeholder}
                      />
                    )}
                  />

                  <input
                    type="hidden"
                    {...register(`socialMedia.${index}.platform`)}
                  />
                </InputGroup>
              );
            })}
          </>
        )}

        {!!fieldsPersonal.length && (
          <>
            <Typography.Text strong>Personal</Typography.Text>

            {fieldsPersonal.map((field, index) => {
              const { dark, placeholder } =
                socialPlatformsIcons[field.platform];

              return (
                <InputGroup key={field.id}>
                  <IconText>
                    {dark({ style: { width: 24, height: 24 } })}
                  </IconText>

                  <Controller
                    control={control}
                    name={`personal.${index}.value`}
                    render={({ field }) => (
                      <Input
                        {...field}
                        size="large"
                        autoComplete="off"
                        placeholder={placeholder}
                      />
                    )}
                  />

                  <input
                    type="hidden"
                    {...register(`personal.${index}.platform`)}
                  />
                </InputGroup>
              );
            })}
          </>
        )}

        {!!fieldsStreaming.length && (
          <>
            <Typography.Text strong>Streaming</Typography.Text>

            {fieldsStreaming.map((field, index) => {
              const { dark, placeholder } =
                socialPlatformsIcons[field.platform];

              return (
                <InputGroup key={field.id}>
                  <IconText>
                    {dark({ style: { width: 24, height: 24 } })}
                  </IconText>

                  <Controller
                    control={control}
                    name={`streaming.${index}.value`}
                    render={({ field }) => (
                      <Input
                        {...field}
                        size="large"
                        autoComplete="off"
                        placeholder={placeholder}
                      />
                    )}
                  />

                  <input
                    type="hidden"
                    {...register(`streaming.${index}.platform`)}
                  />
                </InputGroup>
              );
            })}
          </>
        )}

        {!!fieldsSubscriptionBased.length && (
          <>
            <Typography.Text strong>Subscription-based</Typography.Text>

            {fieldsSubscriptionBased.map((field, index) => {
              const { dark, placeholder } =
                socialPlatformsIcons[field.platform];

              return (
                <InputGroup key={field.id}>
                  <IconText>
                    {dark({ style: { width: 24, height: 24 } })}
                  </IconText>

                  <Controller
                    control={control}
                    name={`subscriptionBased.${index}.value`}
                    render={({ field }) => (
                      <Input
                        {...field}
                        size="large"
                        autoComplete="off"
                        placeholder={placeholder}
                      />
                    )}
                  />

                  <input
                    type="hidden"
                    {...register(`subscriptionBased.${index}.platform`)}
                  />
                </InputGroup>
              );
            })}
          </>
        )}

        {!!fieldsECommerce.length && (
          <>
            <Typography.Text strong>E-commerce</Typography.Text>

            {fieldsECommerce.map((field, index) => {
              const { dark, placeholder } =
                socialPlatformsIcons[field.platform];

              return (
                <InputGroup key={field.id}>
                  <IconText>
                    {dark({ style: { width: 24, height: 24 } })}
                  </IconText>

                  <Controller
                    control={control}
                    name={`eCommerce.${index}.value`}
                    render={({ field }) => (
                      <Input
                        {...field}
                        size="large"
                        autoComplete="off"
                        placeholder={placeholder}
                      />
                    )}
                  />

                  <input
                    type="hidden"
                    {...register(`eCommerce.${index}.platform`)}
                  />
                </InputGroup>
              );
            })}
          </>
        )}

        {!!fieldsDonation.length && (
          <>
            <Typography.Text strong>Donation</Typography.Text>

            {fieldsDonation.map((field, index) => {
              const { dark, placeholder } =
                socialPlatformsIcons[field.platform];

              return (
                <InputGroup key={field.id}>
                  <IconText>
                    {dark({ style: { width: 24, height: 24 } })}
                  </IconText>

                  <Controller
                    control={control}
                    name={`donation.${index}.value`}
                    render={({ field }) => (
                      <Input
                        {...field}
                        size="large"
                        autoComplete="off"
                        placeholder={placeholder}
                      />
                    )}
                  />

                  <input
                    type="hidden"
                    {...register(`donation.${index}.platform`)}
                  />
                </InputGroup>
              );
            })}
          </>
        )}

        {!!fieldsPayment.length && (
          <>
            <Typography.Text strong>Payment</Typography.Text>

            {fieldsPayment.map((field, index) => {
              const { dark, placeholder } =
                socialPlatformsIcons[field.platform];

              return (
                <InputGroup key={field.id}>
                  <IconText>
                    {dark({ style: { width: 24, height: 24 } })}
                  </IconText>

                  <Controller
                    control={control}
                    name={`payment.${index}.value`}
                    render={({ field }) => (
                      <Input
                        {...field}
                        size="large"
                        autoComplete="off"
                        placeholder={placeholder}
                      />
                    )}
                  />

                  <input
                    type="hidden"
                    {...register(`payment.${index}.platform`)}
                  />
                </InputGroup>
              );
            })}
          </>
        )}

        {!!fieldsLinkInBio.length && (
          <>
            <Typography.Text strong>Link-in-bio</Typography.Text>

            {fieldsLinkInBio.map((field, index) => {
              const { dark, placeholder } =
                socialPlatformsIcons[field.platform];

              return (
                <InputGroup key={field.id}>
                  <IconText>
                    {dark({ style: { width: 24, height: 24 } })}
                  </IconText>

                  <Controller
                    control={control}
                    name={`linkInBio.${index}.value`}
                    render={({ field }) => (
                      <Input
                        {...field}
                        size="large"
                        autoComplete="off"
                        placeholder={placeholder}
                      />
                    )}
                  />

                  <input
                    type="hidden"
                    {...register(`linkInBio.${index}.platform`)}
                  />
                </InputGroup>
              );
            })}
          </>
        )}
      </Body>

      {!showMore && fieldsLength > 4 && (
        <div className={styles.showMoreContainer}>
          <Button
            type="text"
            icon={<PlusOutlined />}
            style={{ color: '#5956E9' }}
            onClick={() => setShowMore(true)}
          >
            Show more
          </Button>
        </div>
      )}

      <Footer>
        <Button block size="large" type="primary" htmlType="submit">
          Add Social
        </Button>
      </Footer>
    </form>
  );
};

const EditModalContent: React.FC<{
  setProfileData: React.Dispatch<React.SetStateAction<IProfile>>;
  editableLink: Builder.CurrentMediakit.SocialLink & { index: number };
  setEditableLink: React.Dispatch<
    React.SetStateAction<
      (Builder.CurrentMediakit.SocialLink & { index: number }) | undefined
    >
  >;
}> = ({ editableLink, setEditableLink, setProfileData }) => {
  const { control, register, handleSubmit } = useForm<{
    index: number;
    value: string;
    platform: number;
  }>({
    defaultValues: {
      index: editableLink.index,
      value: editableLink.value,
      platform: editableLink.platform,
    },
  });

  const onSubmit = (data: {
    index: number;
    value: string;
    platform: number;
  }) => {
    setProfileData((prevState) => {
      const newSocialLinks = (prevState?.social_links || []).map((item, i) =>
        i === data.index
          ? {
              value: data.value,
              platform: data.platform,
            }
          : item,
      );

      return {
        ...prevState,
        social_links: newSocialLinks,
      };
    });

    setEditableLink(undefined);
  };

  const { dark, placeholder } = socialPlatformsIcons[editableLink.platform];

  return (
    <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      <Body showMore style={{ paddingBottom: 0 }}>
        <Controller
          name="value"
          control={control}
          rules={{ required: 'This field is required.' }}
          render={({ field, fieldState }) => (
            <Form.Item
              style={{ width: '100%', marginBottom: 0 }}
              validateStatus={!!fieldState.error ? 'error' : undefined}
              help={
                <span style={{ paddingLeft: '3.5rem' }}>
                  {fieldState.error?.message}
                </span>
              }
            >
              <InputGroup>
                <IconText>
                  {dark({ style: { width: 24, height: 24 } })}
                </IconText>
                <Input
                  size="large"
                  autoComplete="off"
                  placeholder={placeholder}
                  {...field}
                />
              </InputGroup>
            </Form.Item>
          )}
        />
        <input type="hidden" {...register('index')} />
        <input type="hidden" {...register('platform')} />
      </Body>
      <Footer>
        <Button block size="large" type="primary" htmlType="submit">
          Save
        </Button>
      </Footer>
    </form>
  );
};

const CustomTag = styled('span')(() => ({
  width: 44,
  height: 44,
  display: 'flex',
  borderRadius: 8,
  position: 'relative',
  alignItems: 'center',
  justifyContent: 'center',
  border: '1px solid rgba(0, 0, 0, 0.15)',
}));

const TagText = styled(Typography.Text)(() => ({
  [`&.${prefixCls}-typography`]: {
    width: 18,
    height: 18,
    display: 'flex',
    alignItems: 'center',
  },
}));

const MoreOutlinedIcon = styled(MoreOutlined)(() => ({
  [`&.anticon.anticon-more`]: {
    top: 2,
    right: -1,
    fontSize: 16,
    position: 'absolute',
    color: 'rgba(0, 0, 0, 0.88)',
  },
}));

const CountrySkeleton = styled(Skeleton)({
  [`&.${prefixCls}-skeleton`]: {
    width: 250,

    [`& > .${prefixCls}-skeleton-content`]: {
      height: 18,
      [`& > .${prefixCls}-skeleton-title`]: {
        height: 18,
        marginBottom: 0,
      },
    },
  },
});

export const AddSocials: React.FC<{
  isEditing?: boolean;
  showSkeleton?: boolean;
  socialLinks?: IProfile['social_links'];
  setProfileData: React.Dispatch<React.SetStateAction<IProfile>>;
}> = (props) => {
  let { isEditing, showSkeleton, socialLinks, setProfileData } = props;

  socialLinks = socialLinks || [];

  const b425 = useBreakpoint(425);
  const location = useSelector(selectLocation);
  const [modalIsOpen, toggleModal] = React.useState<boolean>(false);
  const [editableLink, setEditableLink] = React.useState<
    (Builder.CurrentMediakit.SocialLink & { index: number }) | undefined
  >(undefined);

  const handleModal = () => {
    toggleModal((prevState) => !prevState);
  };

  const onRemoveSocial = (index: number) => {
    setProfileData((prevState) => {
      const social_links = [...(prevState?.social_links || [])];
      social_links.splice(index, 1);

      return {
        ...prevState,
        social_links,
      };
    });
  };

  const dropdownMenu = (
    link: { value: string; platform: number },
    index: number,
  ) => ({
    items: [
      {
        key: 'edit',
        label: 'Edit',
        onClick: () => setEditableLink({ ...link, index }),
      },
      {
        danger: true,
        key: 'delete',
        label: 'Delete',
        onClick: () => onRemoveSocial(index),
      },
    ],
  });

  const showAdd = socialLinks.length < 22 && !showSkeleton;

  if (showSkeleton) {
    return (
      <Flex align="center" justify="center">
        <CountrySkeleton
          paragraph={false}
          active={showSkeleton}
          loading={showSkeleton}
        />
      </Flex>
    );
  }

  if (isEditing) {
    return (
      <Flex gap={10} vertical>
        <Flex>
          <Typography.Text strong>Socials</Typography.Text>
        </Flex>

        <Container>
          {socialLinks?.map((link, index) => {
            const item = socialPlatformsIcons[link.platform];
            return !!item ? (
              <CustomTag key={link.platform}>
                <TagText>
                  {item.dark({ style: { width: 18, height: 18 } })}
                </TagText>

                <Dropdown trigger={['click']} menu={dropdownMenu(link, index)}>
                  <MoreOutlinedIcon />
                </Dropdown>
              </CustomTag>
            ) : null;
          })}

          {showAdd && (
            <Button
              type="dashed"
              title="Add socials"
              id="addSocialsTarget"
              onClick={handleModal}
              icon={<PlusOutlined />}
              disabled={showSkeleton}
              style={{ width: 44, height: 44 }}
            />
          )}
          <Modal
            closable
            footer={null}
            destroyOnClose
            open={modalIsOpen}
            style={modalStyle}
            title="Add Socials"
            okText="Add Social"
            styles={modalStyles}
            onCancel={handleModal}
            width={!b425 ? 430 : undefined}
          >
            <ModalContent
              toggleModal={toggleModal}
              defaultValues={socialLinks}
              setProfileData={setProfileData}
            />
          </Modal>
          <Modal
            closable
            footer={null}
            destroyOnClose
            style={modalStyle}
            okText="Add Social"
            title="Edit Socials"
            styles={modalStyles}
            open={!!editableLink}
            width={!b425 ? 430 : undefined}
            onCancel={() => setEditableLink(undefined)}
          >
            {!!editableLink && (
              <EditModalContent
                editableLink={editableLink}
                setProfileData={setProfileData}
                setEditableLink={setEditableLink}
              />
            )}
          </Modal>
        </Container>
      </Flex>
    );
  }

  return location?.value ? (
    <Flex align="center" justify="center" gap={8}>
      <LogoContainer>
        <LocationIcon fill="#00000073" />
      </LogoContainer>
      <Typography.Text>{location?.value}</Typography.Text>
    </Flex>
  ) : null;
};
