'use client';

import React from 'react';
import styled from '@emotion/styled';
import { Button, Skeleton } from 'antd';
import { EditFilled } from '@ant-design/icons';
import { colors, prefixCls } from '@shared/theme';
import { onUpdateProfile, useDispatch } from '@shared/redux';
import { IProfile } from '@widgets/mediakit/MediaKit/Profile';
import { PosthogEvents } from '@shared/constants/posthog-events';
import { useLocalPostHog } from '@shared/hooks/useLocalPostHog';
import styles from './submit-button.module.css';

interface Props {
  isEditing?: boolean;
  onCancel: () => void;
  profileData: IProfile;
  showSkeleton?: boolean;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
}

const SaveButton = styled(Button)(() => ({
  [`&.${prefixCls}-btn`]: {
    border: 'none',
    fontWeight: 700,
    color: colors[5],
    boxShadow: 'none',
    backgroundColor: 'rgba(89, 86, 233, 0.10)',
  },
  [`&.${prefixCls}-btn.animate-wiggle`]: {
    border: `1px solid ${colors[5]}`,
    animation: 'wiggle 500ms ease-in-out',
  },
  [`:where(&).${prefixCls}-btn-variant-outlined:not(:disabled):not(.${prefixCls}-btn-disabled):hover, :where(&).${prefixCls}-btn-variant-dashed:not(:disabled):not(.${prefixCls}-btn-disabled):hover`]:
    {
      color: colors[6],
      backgroundColor: 'rgba(89, 86, 233, 0.25)',
    },
}));

const EditButton = styled(Button)(() => ({
  [`&.${prefixCls}-btn`]: {
    boxShadow: 'none',
  },
}));

export const SubmitButton: React.FC<Props> = (props) => {
  const { onCancel, isEditing, profileData, showSkeleton, setIsEditing } =
    props;

  const dispatch = useDispatch();

  const posthog = useLocalPostHog();

  if (showSkeleton) {
    return <Skeleton.Button size="large" block shape="round" active />;
  }

  const onEdit = () => {
    setIsEditing(true);

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

    const builderFloatActions = document.getElementById('builderFloatActions');
    if (builderFloatActions) {
      builderFloatActions.style.display = 'none';
    }

    try {
      posthog?.capture(PosthogEvents.click_edit_profile);
    } catch (e) {
      console.error('Posthog error:', e);
    }
  };

  const onSubmit = () => {
    setIsEditing(false);

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

    const builderFloatActions = document.getElementById('builderFloatActions');
    if (builderFloatActions) {
      builderFloatActions.style.removeProperty('display');
    }

    dispatch(onUpdateProfile(profileData));

    try {
      posthog?.capture(PosthogEvents.edit_save);
    } catch (e) {
      console.error('Posthog error:', e);
    }
  };

  const onAnimationEnd = (e: React.AnimationEvent<HTMLButtonElement>) => {
    e.currentTarget.classList.remove('animate-wiggle');
  };

  if (showSkeleton) {
    return <Skeleton.Button size="large" block shape="round" active />;
  }

  return isEditing ? (
    <div className={styles.actionsContainer}>
      <Button block size="large" shape="round" onClick={onCancel}>
        Cancel
      </Button>
      <SaveButton
        block
        size="large"
        shape="round"
        onClick={onSubmit}
        id="saveProfileButton"
        className="animate__animated"
        onAnimationEnd={onAnimationEnd}
      >
        Save
      </SaveButton>
    </div>
  ) : (
    <EditButton
      size="large"
      shape="round"
      color="default"
      variant="filled"
      onClick={onEdit}
      icon={<EditFilled />}
    >
      Edit Profile
    </EditButton>
  );
};
