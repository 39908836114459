export const getTikTokUsername = (url: string): string | null => {
  const regex = /(?:http(?:s)?:\/\/)?(?:www\.)?tiktok\.com\/@([a-zA-Z0-9._]+)/;
  const match = url.match(regex);
  return match ? match[1] : null;
};

export const tikTokValidationRules = (username: string) => {
  const regex = /^[a-zA-Z0-9._-]+$/;
  username = username?.trim() || '';

  if (!username) {
    return true;
  }

  if (getTikTokUsername(username)) {
    return true;
  }

  if (username.length < 1) {
    return 'The tiktok field format is invalid.';
  }

  if (!regex.test(username)) {
    return 'The tiktok field format is invalid.';
  }

  return true;
};
