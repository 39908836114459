import React from 'react';
import { Builder } from '@shared/types/builder';
import { DraggableCancel } from '@shared/constants';
import { Form, Input, InputRef, Modal } from 'antd';
import useBreakpoint from '@shared/hooks/useBreakpoint';
import { largeSize } from '@shared/constants/builderCardSizes';
import { useLinkCrawlMutation } from '@shared/services/mediakit';
import { getBasicComponentsDetails } from '@widgets/mediakit/helpers';
import {
  onUpdateExtraDataItem,
  selectCounter,
  useDispatch,
  useSelector,
} from '@shared/redux';
import { BasicDemoCard } from '@shared/ui/basic-demo-card';
import { useLocalPostHog } from '@shared/hooks/useLocalPostHog';
import { PosthogEvents } from '@shared/constants/posthog-events';

export function LinkDemo({
  data,
  size,
  slug,
  seeAll,
  onAddItem,
}: Builder.DemoComponentProps) {
  const dispatch = useDispatch();
  const posthog = useLocalPostHog();
  const matches = useBreakpoint(400);
  const [craw] = useLinkCrawlMutation();
  const counter = useSelector(selectCounter);
  const inputRef = React.useRef<InputRef>(null);
  const detailsFunc = getBasicComponentsDetails()[data.name];

  const { title, icon } = detailsFunc();

  const [value, handleOnChange] = React.useState(data.link);
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const handleOk = () => {
    setIsModalOpen(false);

    const uid = `${data.name}_+_${counter}`;

    if (value?.trim()) {
      onAddItem &&
        onAddItem({
          currentTarget: {
            dataset: {
              w: largeSize.w,
              h: largeSize.h,
              type: data.name,
              platform: `${data.platform}`,
            },
          },
        } as any);

      dispatch(
        onUpdateExtraDataItem({
          i: uid,
          item: {
            key: 'value',
            value: value?.trim(),
          },
        }),
      );

      if (slug) {
        craw({
          uid,
          slug,
          link: value?.trim(),
        });
      }
    }

    handleOnChange('');
  };

  const showModal = () => {
    setIsModalOpen(true);

    try {
      let properties;
      if (seeAll) {
        properties = {
          seeAll,
        };
      }

      posthog?.capture(PosthogEvents.LinkComponent, properties);

      posthog?.capture(PosthogEvents.click_any_component, {
        component: data.name,
      });
    } catch (e) {
      console.error('Posthog error:', e);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const setAutoFocus = (open: boolean) => {
    if (open) {
      inputRef.current?.focus();
    }

    if (value) {
      handleOnChange('');
    }
  };

  return (
    <>
      <Modal
        okText="Save"
        onOk={handleOk}
        title="Add a Link"
        open={isModalOpen}
        maskClosable={false}
        onCancel={handleCancel}
        className={DraggableCancel}
        afterOpenChange={setAutoFocus}
        rootClassName="DraggableCancel"
        width={matches ? undefined : 360}
        okButtonProps={{ disabled: !value?.trim() }}
      >
        <Form size="large" layout="vertical" component="div">
          <Form.Item label="Link">
            <Input
              type="url"
              name="link"
              value={value}
              ref={inputRef}
              autoComplete="off"
              placeholder="www.example.com"
              onChange={(e) => handleOnChange(e.target.value)}
            />
          </Form.Item>
        </Form>
      </Modal>
      <BasicDemoCard
        icon={icon}
        size={size}
        title={title}
        h={largeSize.h}
        w={largeSize.w}
        type={data.name}
        onClick={showModal}
        platform={data.platform}
      />
    </>
  );
}
