'use client';

import React from 'react';
import { DrawerProps } from 'antd';
import { DrawerBody } from './components/Body';
import { MkitDrawer } from '@shared/ui/MkitDrawer';
import useBreakpoint from '@shared/hooks/useBreakpoint';
import { ResizeIndicator } from '@shared/ui/resize-indicator';
import {
  useDispatch,
  useSelector,
  onCloseDrawer,
  selectDrawerTheme,
  selectDrawerIsOpen,
  selectComponentsDrawerHeight,
  onUpdateComponentsThemeDrawerHeight,
} from '@shared/redux';

const drawerStyles: DrawerProps['styles'] = {
  body: {
    height: '100%',
    display: 'flex',
    paddingInline: 0,
    flexDirection: 'column',
  },
  mask: { backgroundColor: 'transparent' },
};

export const ComponentsDrawer: React.FC<{
  slug: string;
  username: string;
}> = ({ slug, username }) => {
  const dispatch = useDispatch();
  const matches = useBreakpoint(991);
  const drawerTheme = useSelector(selectDrawerTheme);
  const drawerIsOpen = useSelector(selectDrawerIsOpen);
  const height = useSelector(selectComponentsDrawerHeight);

  const [lastUpdate, setLastUpdate] = React.useState<number>(0);
  const [touchStarted, setTouchStarted] = React.useState<boolean>(false);

  const onClose = () => dispatch(onCloseDrawer());

  const setHeight = (h: string | undefined) => {
    dispatch(onUpdateComponentsThemeDrawerHeight(h));
  };

  const onTouchEnd = () => {
    setTouchStarted(false);

    const mkitDrawer = document.querySelector(
      '.mediakit-drawer-content-wrapper',
    );

    mkitDrawer?.classList?.remove('smooth-transition');
  };

  const onTouchStart = () => {
    setTouchStarted(true);

    const mkitDrawer = document.querySelector(
      '.mediakit-drawer-content-wrapper',
    );

    mkitDrawer?.classList?.add('smooth-transition');
  };

  const onTouchMove = (e: React.TouchEvent) => {
    window.stop();

    e.preventDefault();
    const now = Date.now();

    if (now - lastUpdate < 50) return;

    if (touchStarted) {
      let vh = Math.max(
        document.documentElement.clientHeight || 0,
        window.innerHeight || 0,
      );
      const clientY = vh - e.touches[0].clientY;

      let resizedHeight = Math.round((clientY * 100) / vh);
      if (resizedHeight > 80) {
        resizedHeight = 80;
      } else if (resizedHeight < 40) {
        resizedHeight = 40;
      }

      if (height !== `${resizedHeight}vh`) {
        setHeight(`${resizedHeight}vh`);
      }
    }

    setLastUpdate(now);
  };

  const afterOpenChange = (open: boolean) => {
    if (!open) {
      setHeight('80vh');
    }

    if (open) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
  };

  return (
    <MkitDrawer
      destroyOnClose
      onClose={onClose}
      open={drawerIsOpen}
      title="Add Component"
      id="componentsDrawer"
      styles={drawerStyles}
      mkitTheme={drawerTheme}
      paddingXS="0 1rem 2rem 1rem"
      padding="0 2.2rem 2rem 2.2rem"
      afterOpenChange={afterOpenChange}
      width={!matches ? 396 : undefined}
      size={matches ? 'large' : undefined}
      height={matches ? height : undefined}
      placement={matches ? 'bottom' : 'right'}
      classNames={{ wrapper: 'drawer-wrapper' }}
    >
      <ResizeIndicator
        onTouchEnd={onTouchEnd}
        onTouchMove={onTouchMove}
        onTouchStart={onTouchStart}
      />
      <DrawerBody slug={slug} username={username} />
    </MkitDrawer>
  );
};
