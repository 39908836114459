'use client';

import React from 'react';
import { ProBadge } from '@shared/ui/pro-badge';
import { componentsMap } from './componentsMap';
import { YouTubeInput } from './ui/you-tube-input';
import { DemoCardSize } from '@shared/constants/demo-card-size';
import { useUpgradeModal } from '@shared/hooks/useUpgradeModal';
import { useLocalPostHog } from '@shared/hooks/useLocalPostHog';
import { PosthogEvents } from '@shared/constants/posthog-events';
import { SocialComponentsContainer } from '@shared/ui/demo-components-container';
import {
  useSelector,
  selectYouTubeUsername,
  selectYouTubeAccountComponents,
} from '@shared/redux';

const wrapperStyle: React.CSSProperties = {
  position: 'relative',
  width: 'fit-content',
  display: 'inline-flex',
};

const Body: React.FC<{
  onAddItem: (e: React.MouseEvent<HTMLDivElement>) => void;
}> = ({ onAddItem }) => {
  const posthog = useLocalPostHog();
  const { handleOpen } = useUpgradeModal();
  const components = useSelector(selectYouTubeAccountComponents);
  const containerRef = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    containerRef.current?.scrollIntoView();
  }, []);

  return (
    <SocialComponentsContainer ref={containerRef}>
      {components.map((componentData, index) => {
        const Card = componentsMap[componentData.name];
        const isPro = componentData.access === 'pro';

        let click = onAddItem;

        if (isPro) {
          click = () => {
            handleOpen('component', {
              seeAll: true,
              from: componentData.name,
            });

            try {
              posthog?.capture(PosthogEvents.click_any_component, {
                isPro: true,
                component: componentData.name,
              });
            } catch (e) {
              console.error('Posthog error:', e);
            }
          };
        }

        return !!Card ? (
          <div style={wrapperStyle} key={index}>
            <ProBadge isPro={isPro} />
            <Card
              key={index}
              onAddItem={click}
              data={componentData}
              size={DemoCardSize.large}
            />
          </div>
        ) : null;
      })}
    </SocialComponentsContainer>
  );
};

export const YouTubeAccountGroup: React.FC<{
  onAddItem: (e: React.MouseEvent<HTMLDivElement>) => void;
}> = (props) => {
  const { onAddItem } = props;

  const youTubeUsername = useSelector(selectYouTubeUsername);

  return !!youTubeUsername ? <Body onAddItem={onAddItem} /> : <YouTubeInput />;
};
