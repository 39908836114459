import { api } from './api';
import { ApiEndpoints } from '@shared/constants/apiEndpoints';
import { transformErrorResponse } from '@shared/utils/transformErrorResponse';

type QueryArg = Partial<{ tiktok: string; youtube: string; instagram: string }>;

export const profileApi = api.injectEndpoints({
  endpoints: (builder) => ({
    addSocialAccounts: builder.mutation<any, QueryArg>({
      transformErrorResponse,
      query: (body) => ({
        body,
        method: 'POST',
        url: ApiEndpoints.addSocialAccounts,
      }),
    }),
  }),
});

export const { useAddSocialAccountsMutation } = profileApi;
