import { CSSObject } from '@emotion/styled';

export const gradientStyles = (background: string): CSSObject => {
  return {
    top: 0,
    left: 0,
    zIndex: 0,
    background,
    width: '100%',
    opacity: 0.32,
    height: '100%',
    position: 'fixed',
    minWidth: '100vw',
    minHeight: '100vh',
  };
};

export const imageContainerStyles: CSSObject = {
  top: 0,
  left: 0,
  zIndex: 0,
  width: '100%',
  height: '100%',
  position: 'fixed',
  minWidth: '100vw',
  minHeight: '100vh',
  alignItems: 'center',
  justifyContent: 'center',
};
