import React from 'react';
import Image from 'next/image';
import { Flex, Typography } from 'antd';
import { Builder } from '@shared/types/builder';
import igIcon from '@shared/icons/instagram.svg';
import { Text, Title } from '@shared/ui/typography';
import { onAddItem, useDispatch } from '@shared/redux';
import { fontVariants } from '@shared/constants/fontVariants';
import { largeSize } from '@shared/constants/builderCardSizes';
import fallback from '@shared/icons/instagram-profile-image.svg';
import { abbreviateNumber } from '@shared/utils/abbreviateNumber';
import { ComponentNames } from '@shared/constants/componentNames';
import { SocialProfileTarget } from '@widgets/mkit-tour/ui/social-profile-target';
import { CardHelper } from '@widgets/mediakit/components-drawer/components/CardHelper';
import {
  ProfileCard,
  HandleContainer,
  ProfileCardBody,
  ProfileCardHead,
  ProfileCardHeadExtra,
} from './styles';

export function InstagramProfileBasicDemo({
  data,
  size,
}: Builder.DemoComponentProps) {
  const dispatch = useDispatch();

  const addSocial = () => {
    dispatch(
      onAddItem({
        w: largeSize.w,
        h: largeSize.h,
        i: ComponentNames.instagram_profile_basic,
      }),
    );
  };

  return (
    <ProfileCard>
      <SocialProfileTarget />
      <ProfileCardBody
        size={size}
        onClick={addSocial}
        data-w={largeSize.w}
        data-h={largeSize.h}
        data-type={data.name}
        style={{ justifyContent: 'flex-start' }}
      >
        <ProfileCardHead>
          <ProfileCardHeadExtra>
            <Image width={17} height={17} src={igIcon} alt="Instagram" />
            <HandleContainer>
              <Title ellipsis level={5}>
                @{data.handle}
              </Title>
            </HandleContainer>
          </ProfileCardHeadExtra>

          {data.account_type ? (
            <div
              style={{
                borderRadius: '99px',
                background: 'rgba(0, 0, 0, 0.05)',
              }}
            >
              <Title
                type="secondary"
                style={{ fontSize: 8, padding: '4px 8px' }}
              >
                {data.account_type === 'MEDIA_CREATOR'
                  ? 'Media Creator'
                  : 'Business'}
              </Title>
            </div>
          ) : (
            <Title ellipsis level={5} />
          )}
        </ProfileCardHead>

        <Flex gap={8} align="center" style={{ marginTop: 8 }}>
          <Image
            alt={''}
            width={20}
            height={20}
            style={{ borderRadius: '50%' }}
            src={data.profile_image || fallback}
          />

          <Flex vertical gap={2} align="flex-start">
            <Title ellipsis level={5} style={{ fontSize: 8, marginBottom: 0 }}>
              {data?.full_name || '-'}
            </Title>

            {!!data?.link && (
              <Text
                ellipsis
                type="secondary"
                style={{ fontSize: 8 }}
                className={fontVariants.opt2}
              >
                @{data?.handle}
              </Text>
            )}
          </Flex>
        </Flex>

        <Flex gap={16} style={{ marginTop: 8 }}>
          <Flex vertical gap={8}>
            <Typography.Text type="secondary" style={{ fontSize: 8 }}>
              Posts
            </Typography.Text>

            <Typography.Title style={{ margin: 0, fontSize: 8 }}>
              {data?.media_count || 0}
            </Typography.Title>
          </Flex>

          <Flex vertical gap={8}>
            <Typography.Text style={{ fontSize: 8 }} type="secondary">
              Followers
            </Typography.Text>
            <Typography.Title style={{ margin: 0, fontSize: 8 }}>
              {abbreviateNumber(data?.followers || 0)}
            </Typography.Title>
          </Flex>

          <Flex vertical gap={8}>
            <Typography.Text style={{ fontSize: 8 }} type="secondary">
              Following
            </Typography.Text>
            <Typography.Title style={{ margin: 0, fontSize: 8 }}>
              {data?.followings || 0}
            </Typography.Title>
          </Flex>
        </Flex>
      </ProfileCardBody>
      <CardHelper size={size}>Overview</CardHelper>
    </ProfileCard>
  );
}
