import React from 'react';
import styled from '@emotion/styled';
import { prefixCls } from '@shared/theme';
import { LinkOutlined } from '@ant-design/icons';
import { MkitDrawer } from '@shared/ui/MkitDrawer';
import useBreakpoint from '@shared/hooks/useBreakpoint';
import { ButtonCustomSkeleton } from '../ButtonSkeleton';
import { useLocalPostHog } from '@shared/hooks/useLocalPostHog';
import { PosthogEvents } from '@shared/constants/posthog-events';
import { MediaKitStatus } from '@shared/constants/media-kit-status';
import { Form, Select, Button, Popover, Typography, DrawerProps } from 'antd';
import {
  useDispatch,
  useSelector,
  onOpenShareModal,
  selectDrawerTheme,
  onCloseShareModal,
  selectCurrentMediakit,
  selectShareModalIsOpen,
  selectAuthUserDeactivated,
} from '@shared/redux';
import {
  useEnableMutation,
  useDisableMutation,
} from '@shared/services/mediakit';

const StyledText = styled(Typography.Text)(() => ({
  [`&.${prefixCls}-typography`]: {
    display: 'flex',
    position: 'relative',
    [`& > .${prefixCls}-typography-copy`]: {
      top: 0,
      left: 0,
      bottom: 0,
      margin: 0,
      width: '100%',
      alignItems: 'center',
      position: 'absolute',
      display: 'flex !important',
      '& > span': {
        left: 16,
        position: 'relative',
      },
    },
  },
  '& .anticon': {
    transition: 'all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1)',
  },
  '& .anticon.anticon-copy': {
    display: 'none',
  },
  '& .anticon.anticon-check': {
    display: 'none',
  },
}));

const drawerStyles: DrawerProps['styles'] = {
  body: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  mask: { backgroundColor: 'transparent' },
};

const ButtonSkeleton = () => {
  const is768 = useBreakpoint(768);

  const style = {
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    width: is768 ? 40 : 98,
    minWidth: is768 ? 'unset' : void 0,
  };

  return <ButtonCustomSkeleton style={style} />;
};

const options = [
  {
    label: 'Anyone with the link can view',
    value: MediaKitStatus.PUBLIC,
  },
  {
    label: 'Private Media Kit',
    value: MediaKitStatus.DISABLED,
  },
];

export const ShareButton: React.FC<{
  slug: string;
  username: string;
  scrolled?: boolean;
  showSkeleton?: boolean;
}> = ({ slug, scrolled, showSkeleton }) => {
  const dispatch = useDispatch();
  const posthog = useLocalPostHog();
  const matches = useBreakpoint(768);

  const [enable, enableRes] = useEnableMutation();
  const [disable, disableRes] = useDisableMutation();

  const [value, setValue] = React.useState('');

  const drawerTheme = useSelector(selectDrawerTheme);
  const mediakit = useSelector(selectCurrentMediakit);
  const modalIsOpen = useSelector(selectShareModalIsOpen);
  const deactivated = useSelector(selectAuthUserDeactivated);

  const isPublic = mediakit.profile.status === MediaKitStatus.PUBLIC;

  React.useEffect(() => cleanCopyId, []);

  React.useEffect(() => {
    if (typeof window === 'object' && mediakit?.profile?.handle) {
      setValue(`${window.location.origin}/${mediakit?.profile?.handle}`);
    }
  }, [mediakit?.profile?.handle]);

  const copyIdRef = React.useRef<any>(null);

  const [copied, setCopied] = React.useState(false);

  const onCopyClick = () => {
    setCopied(true);
    cleanCopyId();

    copyIdRef.current = setTimeout(() => {
      setCopied(false);
    }, 3000);

    try {
      posthog?.capture(PosthogEvents.click_copy_link);
    } catch (e) {
      console.error('Posthog error:', e);
    }
  };

  const cleanCopyId = () => {
    if (copyIdRef.current) {
      clearTimeout(copyIdRef.current);
    }
  };

  const onOpenChange = (open: boolean) => {
    if (open) {
      dispatch(onOpenShareModal());
    } else {
      dispatch(onCloseShareModal());
    }
  };

  const afterOpenChange = (open: boolean) => {
    if (open) {
      try {
        posthog?.capture(PosthogEvents.ShareClicked);
      } catch (e) {
        console.error('Posthog error:', e);
      }
    }
  };

  const onOpen = () => dispatch(onOpenShareModal());

  const icon = scrolled ? null : (
    <svg
      width="16"
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillOpacity="0.88"
        fill="currentColor"
        d="M3 7.83328V12.6666C3 12.755 3.03512 12.8398 3.09763 12.9023C3.16014 12.9648 3.24493 13 3.33333 13H12.6667C12.7551 13 12.8399 12.9648 12.9024 12.9023C12.9649 12.8398 13 12.755 13 12.6666V7.83328C13 7.70068 13.0527 7.5735 13.1464 7.47973C13.2402 7.38596 13.3674 7.33328 13.5 7.33328C13.6326 7.33328 13.7598 7.38596 13.8536 7.47973C13.9473 7.5735 14 7.70068 14 7.83328V12.6666C14 13.0202 13.8595 13.3594 13.6095 13.6094C13.3594 13.8595 13.0203 14 12.6667 14H3.33333C2.97971 14 2.64057 13.8595 2.39052 13.6094C2.14048 13.3594 2 13.0202 2 12.6666V7.83328C2 7.70068 2.05268 7.5735 2.14645 7.47973C2.24021 7.38596 2.36739 7.33328 2.5 7.33328C2.63261 7.33328 2.75979 7.38596 2.85355 7.47973C2.94732 7.5735 3 7.70068 3 7.83328ZM8.54867 3.70662V10.1666C8.54867 10.2992 8.49599 10.4264 8.40222 10.5202C8.30845 10.6139 8.18127 10.6666 8.04867 10.6666C7.91606 10.6666 7.78888 10.6139 7.69511 10.5202C7.60135 10.4264 7.54867 10.2992 7.54867 10.1666L7.548 3.70662L5.52333 5.84995C5.42827 5.93699 5.30331 5.98401 5.17445 5.98124C5.04559 5.97846 4.92277 5.9261 4.83154 5.83504C4.74032 5.74399 4.68773 5.62127 4.68471 5.49242C4.68169 5.36356 4.72848 5.23851 4.81533 5.14328L7.694 2.14662C7.78789 2.05274 7.91523 2 8.048 2C8.18077 2 8.30811 2.05274 8.402 2.14662L11.1887 5.14328C11.2351 5.18968 11.2719 5.24477 11.297 5.30539C11.3221 5.36602 11.335 5.431 11.335 5.49662C11.335 5.56224 11.3221 5.62721 11.297 5.68784C11.2719 5.74846 11.2351 5.80355 11.1887 5.84995C11.1423 5.89635 11.0872 5.93316 11.0266 5.95827C10.9659 5.98338 10.901 5.99631 10.8353 5.99631C10.7697 5.99631 10.7047 5.98338 10.6441 5.95827C10.5835 5.93316 10.5284 5.89635 10.482 5.84995L8.54867 3.70662Z"
      />
    </svg>
  );

  const disabled = enableRes.isLoading || disableRes.isLoading || !isPublic;

  const content = (
    <Form style={{ width: 350 }} component="div" layout="vertical">
      <Form.Item
        label="General Access"
        style={{ marginBottom: 40, marginTop: 24 }}
        help={
          mediakit.profile.status === MediaKitStatus.PUBLIC
            ? 'Anyone with the link can view your media kit.'
            : 'No one can view your media kit.'
        }
      >
        <Select
          size="large"
          options={options}
          value={mediakit.profile.status}
          loading={enableRes.isLoading || disableRes.isLoading}
          onChange={(value) => {
            if (value === MediaKitStatus.PUBLIC) {
              enable(slug);
            } else {
              disable(slug);
            }
          }}
        />
      </Form.Item>

      <StyledText
        copyable={
          disabled
            ? false
            : {
                icon: <></>,
                text: value,
                onCopy: () => onCopyClick(),
              }
        }
      >
        <Button
          block
          size="large"
          type="primary"
          disabled={disabled}
          icon={<LinkOutlined />}
        >
          {copied ? 'Link Copied!' : 'Copy Link'}
        </Button>
      </StyledText>
    </Form>
  );

  if (deactivated) {
    return null;
  }

  return matches ? (
    <>
      {showSkeleton ? (
        <ButtonSkeleton />
      ) : (
        <Button
          icon={icon}
          size="large"
          type="primary"
          onClick={onOpen}
          id="share-button"
          style={{
            borderRadius: scrolled ? 99 : 12,
            boxShadow:
              '0px 1px 2px 0px rgba(255, 255, 255, 0.25) inset, 0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
          }}
        >
          {scrolled ? (
            <span style={{ lineHeight: 1, marginTop: 1 }}>Share</span>
          ) : (
            !matches && (
              <span style={{ lineHeight: 1, marginTop: 1 }}>Share</span>
            )
          )}
        </Button>
      )}
      <MkitDrawer
        size="large"
        title="Share"
        height="35vh"
        placement="bottom"
        paddingXS="1.6rem"
        open={modalIsOpen}
        styles={drawerStyles}
        mkitTheme={drawerTheme}
        afterOpenChange={afterOpenChange}
        classNames={{ wrapper: 'drawer-wrapper' }}
        onClose={() => dispatch(onCloseShareModal())}
      >
        <Form component="div" layout="vertical">
          <Form.Item
            style={{ marginBottom: 40, marginTop: 24 }}
            label={<span style={{ color: '#FFFFFFE0' }}>General Access</span>}
            help={
              mediakit.profile.status === MediaKitStatus.PUBLIC ? (
                <span style={{ color: '#FFFFFFE0' }}>
                  Anyone with the link can view your media kit.
                </span>
              ) : (
                <span style={{ color: '#FFFFFFE0' }}>
                  No one can view your media kit.
                </span>
              )
            }
          >
            <Select
              size="large"
              options={[
                {
                  label: 'Anyone with the link can view',
                  value: MediaKitStatus.PUBLIC,
                },
                {
                  label: 'Private Media Kit',
                  value: MediaKitStatus.DISABLED,
                },
              ]}
              value={mediakit.profile.status}
              loading={enableRes.isLoading || disableRes.isLoading}
              onChange={(value) => {
                if (value === MediaKitStatus.PUBLIC) {
                  enable(slug);
                } else {
                  disable(slug);
                }
              }}
            />
          </Form.Item>

          <StyledText
            copyable={
              disabled
                ? false
                : {
                    icon: <></>,
                    text: value,
                    onCopy: () => onCopyClick(),
                  }
            }
          >
            <Button
              block
              size="large"
              type="primary"
              disabled={disabled}
              icon={<LinkOutlined />}
              style={{
                backgroundColor: disabled ? '#D3D3D3' : void 0,
              }}
            >
              {copied ? 'Link Copied!' : 'Copy Link'}
            </Button>
          </StyledText>
        </Form>
      </MkitDrawer>
    </>
  ) : (
    <>
      {showSkeleton ? (
        <ButtonSkeleton />
      ) : (
        <Popover
          title="Share"
          trigger="click"
          content={content}
          open={modalIsOpen}
          placement="bottomRight"
          onOpenChange={onOpenChange}
          afterOpenChange={afterOpenChange}
        >
          <Button
            icon={icon}
            size="large"
            type="primary"
            id="share-button"
            style={{
              borderRadius: scrolled ? 99 : void 0,
              boxShadow:
                '0px 1px 2px 0px rgba(255, 255, 255, 0.25) inset, 0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
            }}
          >
            {scrolled ? (
              <span style={{ lineHeight: 1, marginTop: 1 }}>Share</span>
            ) : (
              !matches && (
                <span style={{ lineHeight: 1, marginTop: 1 }}>Share</span>
              )
            )}
          </Button>
        </Popover>
      )}
    </>
  );
};
