'use client';

import styled, { CSSObject } from '@emotion/styled';
import { Layout as AntdLayout } from 'antd';
import {
  breakpoints,
  builderBreakpoint,
  previewHeightDiff,
  previewMTDiff,
} from '@shared/theme';

export const Content = styled(AntdLayout.Content, {
  shouldForwardProp: (propName) => propName !== 'contentStyles',
})(({ contentStyles }: { contentStyles?: CSSObject }) => ({
  display: 'flex',
  padding: '1.2rem',
  '& > div': {
    width: '100%',
  },
  ...(contentStyles as any),
}));

export const BuilderContent = styled(AntdLayout.Content)(() => ({
  display: 'flex',
  padding: '2.4rem',
  '& > div': {
    width: '100%',
  },
  '&.mobile': {
    width: 375,
    margin: 'auto',
    padding: '1.6rem',
  },
  [`@media (max-width: ${breakpoints.lg})`]: {
    padding: '1.6rem',
  },
}));

export const AdditionalContainer = styled('div')(() => ({
  padding: 0,
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  '& > div': {
    width: '100%',
  },
  '&.mobile': {
    width: 375,
    zIndex: 999,
    margin: '0 auto',
    borderRadius: 12,
    overflowY: 'auto',
    overflowX: 'hidden',
    marginTop: previewMTDiff,
    height: `calc(100vh - ${previewHeightDiff}px)`,
    maxHeight: `calc(100vh - ${previewHeightDiff}px)`,
  },
}));

export const BuilderContainer = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  paddingBottom: '18rem',
  justifyContent: 'center',
  '&.mobile': {
    paddingBottom: '8rem',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  [`@media (max-width: ${builderBreakpoint})`]: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  [`@media (max-width: ${breakpoints.lg})`]: {
    paddingBottom: '8rem',
  },
}));
