'use client';

import React from 'react';
import Image from 'next/image';
import styled from '@emotion/styled';
import { prefixCls } from '@shared/theme';
import { Button, Flex, Modal, Typography } from 'antd';
import useBreakpoint from '@shared/hooks/useBreakpoint';
import { ButtonCustomSkeleton } from '../ButtonSkeleton';
import { useLocalPostHog } from '@shared/hooks/useLocalPostHog';
import { PosthogEvents } from '@shared/constants/posthog-events';
import c390 from '@shared/icons/c390f8c4e57dc493345e806fbd4d5da5.gif';
import {
  useSelector,
  useDispatch,
  onChangeBuilderPreviewMode,
  selectBuilderPreviewIsMobile,
} from '@shared/redux';

const buttonStyle: React.CSSProperties = {
  width: 36,
  height: 36,
  padding: 0,
  display: 'flex',
  borderRadius: 8,
  alignItems: 'center',
};

const Container = styled('div')(() => ({
  gap: 4,
  display: 'flex',
  padding: '.2rem',
  alignItems: 'center',
  borderRadius: '1rem',
  backgroundColor: 'rgba(255, 255, 255, 0.10)',
  [`& > .${prefixCls}-divider`]: {
    height: '100%',
    borderInlineStart: '1px solid rgba(0, 0, 0, 0.2)',
  },
}));

export const ViewToggle: React.FC<{ showSkeleton?: boolean }> = ({
  showSkeleton,
}) => {
  const dispatch = useDispatch();
  const b768 = useBreakpoint(768);
  const posthog = useLocalPostHog();
  const isMobile = useSelector(selectBuilderPreviewIsMobile);
  const [modalVisible, setModalVisible] = React.useState(false);

  React.useEffect(() => {
    if (b768) {
      dispatch(onChangeBuilderPreviewMode('desktop'));
    }
  }, [b768, dispatch]);

  const setView = (view: 'desktop' | 'mobile') => {
    if (b768) {
      setModalVisible(true);
    } else {
      setModalVisible(false);
      dispatch(onChangeBuilderPreviewMode(view));
    }

    try {
      posthog?.capture(PosthogEvents.SwitcherClicked, {
        [view]: true,
      });
    } catch (e) {
      console.error('Posthog error:', e);
    }
  };

  return b768 ? null : showSkeleton ? (
    <ButtonCustomSkeleton style={{ ...buttonStyle, height: void 0 }} />
  ) : (
    <Container>
      <Button
        type="link"
        size="small"
        onClick={() => setView('desktop')}
        style={{
          ...buttonStyle,
          background: !isMobile ? '#fff' : 'transparent',
        }}
      >
        <svg
          width="20"
          height="20"
          fill="none"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillOpacity={!isMobile ? 0.88 : 1}
            fill={!isMobile ? '#000' : '#fff'}
            d="M18.125 2.73438H1.875C1.5293 2.73438 1.25 3.01367 1.25 3.35938V13.0469C1.25 13.3926 1.5293 13.6719 1.875 13.6719H9.29688V15.8594H5.9375C5.76562 15.8594 5.625 16 5.625 16.1719V17.1094C5.625 17.1953 5.69531 17.2656 5.78125 17.2656H14.2188C14.3047 17.2656 14.375 17.1953 14.375 17.1094V16.1719C14.375 16 14.2344 15.8594 14.0625 15.8594H10.7031V13.6719H18.125C18.4707 13.6719 18.75 13.3926 18.75 13.0469V3.35938C18.75 3.01367 18.4707 2.73438 18.125 2.73438ZM17.3438 12.2656H2.65625V4.14062H17.3438V12.2656Z"
          />
        </svg>
      </Button>

      <Button
        type="link"
        size="small"
        onClick={() => setView('mobile')}
        style={{
          ...buttonStyle,
          background: isMobile ? '#fff' : 'transparent',
        }}
      >
        <svg
          width="20"
          height="20"
          fill="none"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillOpacity={isMobile ? 0.88 : 1}
            fill={isMobile ? '#000' : '#fff'}
            d="M14.5312 1.21094H5.46875C4.7793 1.21094 4.21875 1.77148 4.21875 2.46094V17.4609C4.21875 18.1504 4.7793 18.7109 5.46875 18.7109H14.5312C15.2207 18.7109 15.7812 18.1504 15.7812 17.4609V2.46094C15.7812 1.77148 15.2207 1.21094 14.5312 1.21094ZM14.375 17.3047H5.625V2.61719H14.375V17.3047ZM9.21875 15.3125C9.21875 15.5197 9.30106 15.7184 9.44757 15.8649C9.59409 16.0114 9.7928 16.0938 10 16.0938C10.2072 16.0938 10.4059 16.0114 10.5524 15.8649C10.6989 15.7184 10.7812 15.5197 10.7812 15.3125C10.7812 15.1053 10.6989 14.9066 10.5524 14.7601C10.4059 14.6136 10.2072 14.5312 10 14.5312C9.7928 14.5312 9.59409 14.6136 9.44757 14.7601C9.30106 14.9066 9.21875 15.1053 9.21875 15.3125Z"
          />
        </svg>
      </Button>
      <Modal
        width={360}
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={
          <Flex align="center" justify="center">
            <Button onClick={() => setModalVisible(false)} type="primary">
              Ok
            </Button>
          </Flex>
        }
      >
        <Flex vertical align="center" justify="center">
          <Image src={c390} alt={''} />
        </Flex>
        <Typography.Text
          style={{
            display: 'flex',
            textAlign: 'center',
            marginTop: '1.6rem',
            marginBottom: '2.4rem',
            justifyContent: 'center',
          }}
        >
          Edit the desktop version on your <br />
          computer for a better experience.
        </Typography.Text>
      </Modal>
    </Container>
  );
};
