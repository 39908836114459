'use client';

import React from 'react';
import styled from '@emotion/styled';
import { Tags } from './components/Tags';
import { prefixCls } from '@shared/theme';
import { Flex, Skeleton, Typography } from 'antd';
import { AddNewTag } from './components/AddNewTag';
import { Categories } from '@shared/types/categories';
import { useOnboardingMutation } from '@shared/services/auth';
import { IProfile } from '@widgets/mediakit/MediaKit/Profile';
import { useLocalPostHog } from '@shared/hooks/useLocalPostHog';
import { PosthogEvents } from '@shared/constants/posthog-events';
import {
  useSelector,
  selectSelectedCategories,
  selectCategoriesHasSelected,
} from '@shared/redux';

const TagsContainer = styled('div')(() => ({
  gap: '.8rem',
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'flex-start',
}));

const TagSkeleton = styled(Skeleton)(() => ({
  [`&.${prefixCls}-skeleton`]: {
    width: 44,
    height: 32,
    [`& > .${prefixCls}-skeleton-content`]: {
      width: 44,
      height: 32,
      [`& > .${prefixCls}-skeleton-title`]: {
        width: 44,
        height: 32,
        marginBottom: 0,
      },
    },
  },
}));

export const CategoriesSelect: React.FC<{
  isEditing?: boolean;
  showSkeleton?: boolean;
  categories?: IProfile['categories'];
  setProfileData: React.Dispatch<React.SetStateAction<IProfile>>;
}> = (props) => {
  const { isEditing, showSkeleton, categories, setProfileData } = props;

  const posthog = useLocalPostHog();
  const inputRef = React.useRef<any>(null);
  const [onboarding] = useOnboardingMutation();
  const [inputVisible, setInputVisible] = React.useState(false);
  const selectedCategories = useSelector(selectSelectedCategories);
  const categoriesHasSelected = useSelector(selectCategoriesHasSelected);

  React.useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);

  const onBlur = () => {
    setInputVisible(false);
  };

  const showInput = () => {
    setInputVisible(true);
  };

  const onSelect = (
    _value: Categories.Category['value'],
    option?: Categories.Category,
  ) => {
    if (option) {
      setProfileData((prevState) => ({
        ...prevState,
        categories: [...(prevState?.categories || []), option],
      }));

      if (!categoriesHasSelected) {
        onboarding({ step: 'general_info.categories' });
      }

      try {
        const labels = selectedCategories.map((category) => category.label);

        labels.push(option.label);

        posthog?.capture(PosthogEvents.AddedCategory, {
          categories: labels,
        });
      } catch (e) {
        console.error('Posthog error:', e);
      }
    }

    setInputVisible(false);
  };

  if (isEditing) {
    return (
      <Flex vertical gap={10}>
        <Flex>
          <Typography.Text strong>
            Categories ({categories?.length || 0}/5)
          </Typography.Text>
        </Flex>
        <TagsContainer>
          <Tags
            isEditing={true}
            categories={categories}
            setProfileData={setProfileData}
          />
          <AddNewTag
            ref={inputRef}
            onBlur={onBlur}
            onSelect={onSelect}
            showInput={showInput}
            disabled={showSkeleton}
            categories={categories}
            showSkeleton={showSkeleton}
            inputVisible={inputVisible}
          />
        </TagsContainer>
      </Flex>
    );
  }

  if (showSkeleton) {
    return (
      <Flex align="center" justify="center" gap={5} style={{ width: '100%' }}>
        <TagSkeleton
          paragraph={false}
          active={showSkeleton}
          loading={showSkeleton}
        />
        <TagSkeleton
          paragraph={false}
          active={showSkeleton}
          loading={showSkeleton}
        />
        <TagSkeleton
          paragraph={false}
          active={showSkeleton}
          loading={showSkeleton}
        />
      </Flex>
    );
  }

  return categories?.length ? (
    <TagsContainer style={{ marginBlock: 12, justifyContent: 'center' }}>
      <Tags
        isEditing={false}
        categories={categories}
        setProfileData={setProfileData}
      />
    </TagsContainer>
  ) : null;
};
