'use client';

import React from 'react';
import styled from '@emotion/styled';
import { Flex, Skeleton } from 'antd';
import { IProfile } from '../../index';
import { BorderedInput } from './styled';
import { prefixCls } from '@shared/theme';
import { Title } from '@shared/ui/typography';
import { fontVariants } from '@shared/constants/fontVariants';
import { useLocalPostHog } from '@shared/hooks/useLocalPostHog';
import { PosthogEvents } from '@shared/constants/posthog-events';
import { Unverified } from '@widgets/verification/ui/unverified';

const NameSkeleton = styled(Skeleton)({
  [`&.${prefixCls}-skeleton`]: {
    width: '100%',
    [`& > .${prefixCls}-skeleton-content`]: {
      height: 32,
      [`& > .${prefixCls}-skeleton-title`]: {
        height: 32,
        marginBottom: 0,
      },
    },
  },
});

export const NameInput: React.FC<{
  loading?: boolean;
  isEditing?: boolean;
  showSkeleton?: boolean;
  name?: IProfile['name'];
  setProfileData: React.Dispatch<React.SetStateAction<IProfile>>;
}> = (props) => {
  const { name, loading, isEditing, showSkeleton, setProfileData } = props;

  const posthog = useLocalPostHog();

  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value?.trim() || '';

    if (value) {
      try {
        posthog?.capture(PosthogEvents.AddedName, {
          name: value,
        });
      } catch (e) {
        console.error('Posthog error:', e);
      }
    }
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setProfileData((prevState) => ({
      ...prevState,
      name: {
        value: e.target.value,
      },
    }));
  };

  let titleStyle = {
    maxWidth: '100%',
    padding: '8px 4px',
    alignItems: 'center',
  };

  const userName = name?.value?.trim() || '';

  const flexStyle = { width: '100%' };

  if (loading) {
    return (
      <Flex align="center" justify="center">
        <NameSkeleton paragraph={false} active={loading} loading={loading} />
      </Flex>
    );
  }

  if (isEditing) {
    return (
      <Flex
        gap={8}
        vertical
        align="center"
        justify="center"
        style={flexStyle}
        id="nameInputContainer"
      >
        <NameSkeleton paragraph={false} active={loading} loading={loading}>
          <BorderedInput
            size="large"
            id="nameInput"
            onBlur={onBlur}
            variant="outlined"
            placeholder="Your Name"
            value={name?.value || ''}
            onChange={handleOnChange}
            className={fontVariants.opt1}
            autoFocus
          />
        </NameSkeleton>
      </Flex>
    );
  }

  return (
    <Flex
      gap={8}
      vertical
      align="center"
      justify="center"
      style={flexStyle}
      id="nameInputContainer"
    >
      {!!userName && (
        <Title
          ellipsis
          level={3}
          titleProps={titleStyle}
          className={fontVariants.opt1}
        >
          {userName}
        </Title>
      )}

      <Unverified showSkeleton={showSkeleton} />
    </Flex>
  );
};
