export const getInstagramUsername = (url: string): string | null => {
  const regex =
    /(?:http(?:s)?:\/\/)?(?:www\.)?instagram\.com\/([a-zA-Z0-9._]+)/;

  const match = url.match(regex);

  return match ? match[1] : null;
};

export const instagramValidationRules = (username: string) => {
  const regex = /^[a-zA-Z0-9._-]+$/;
  username = username?.trim() || '';

  if (!username) {
    return true;
  }

  if (getInstagramUsername(username)) {
    return true;
  }

  if (username.length < 3) {
    return 'The instagram field format is invalid.';
  }

  if (!regex.test(username)) {
    return 'The instagram field format is invalid.';
  }

  return true;
};
