'use client';

import React from 'react';
import { Button, Input } from 'antd';
import { onUpdateAuthUser, useDispatch } from '@shared/redux';
import { useLocalPostHog } from '@shared/hooks/useLocalPostHog';
import { useFetchComponentsMutation } from '@shared/services/mediakit';
import { useAddSocialAccountsMutation } from '@shared/services/profile';
import { getYouTubeUsername } from '@shared/utils/get-you-tube-username';
import {
  Control,
  useForm,
  useWatch,
  Controller,
  useFormState,
} from 'react-hook-form';
import styles from './you-tube-input.module.css';
import { PosthogEvents } from '@shared/constants/posthog-events';

interface IFormValues {
  youtube: string;
}

export const YouTubeInput: React.FC = () => {
  const dispatch = useDispatch();
  const posthog = useLocalPostHog();
  const [fetchComponents] = useFetchComponentsMutation();
  const [storeSocialAccounts] = useAddSocialAccountsMutation();
  const containerRef = React.useRef<HTMLDivElement | null>(null);

  const { control, handleSubmit } = useForm<IFormValues>({
    defaultValues: { youtube: '' },
  });

  React.useEffect(() => {
    containerRef.current?.scrollIntoView();
  }, []);

  const onSubmit = async (formValues: IFormValues) => {
    try {
      let youtube = getYouTubeUsername(formValues.youtube);

      if (!youtube) {
        youtube = formValues.youtube;
      }

      await storeSocialAccounts({ youtube }).unwrap();

      try {
        posthog?.capture(PosthogEvents.enter_handle_yt);
      } catch (e) {
        console.error('Posthog error:', e);
      }

      await fetchComponents();

      dispatch(onUpdateAuthUser({ youtube }));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <div ref={containerRef}>
        <div className={styles.card}>
          <div className={styles.formItem}>
            <span className={styles.text}>
              Enter your YouTube username to attach your account and access your
              data.
            </span>
            <Controller
              name="youtube"
              control={control}
              render={({ field }) => (
                <Input
                  prefix="@"
                  size="large"
                  placeholder="Enter Your YouTube Username"
                  {...field}
                />
              )}
            />
          </div>

          <SubmitButton control={control} />
        </div>
      </div>
    </form>
  );
};

const SubmitButton: React.FC<{ control: Control<IFormValues> }> = ({
  control,
}) => {
  const { isSubmitting } = useFormState({ control });
  const youtube = useWatch({ control, name: 'youtube' });

  return (
    <Button
      block
      size="large"
      type="primary"
      htmlType="submit"
      loading={isSubmitting}
      className={styles.button}
      disabled={!youtube?.trim()}
    >
      Save
    </Button>
  );
};
